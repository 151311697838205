import React, {useState, useEffect, useContext} from 'react';
import PlayhouseWordWheel from '../assets/playhouse_wheel.svg';
import PlayhouseIcon from '../assets/playhouse_icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBagShopping } from '@fortawesome/free-solid-svg-icons'
import { ShopContext } from './shopContext'

function About() {
    const { fetchProductByHandle, addItemToCheckout , product, checkout } = useContext(ShopContext)

    return (
        <div id="page-wrapper">
            <div className="about-top-wrapper">
                <div onClick={() => window.location.href = "/"} className="page-logo-wrapper">
                    <img style={{width: "100%", margin: "auto"}} src="https://lh3.googleusercontent.com/sOGO8IJR5ZC9Zs3GNYcFu5KjglIaFMOyvWMGFNNYRgs51YR2LnY_67A7raRFMbPZrQQwVRrgYmX-VG0R8F0MF5aDAH17Yu3Ij-JFdiZveZmdw0p3_5JoP8H1VeCx_3ziprad9uiq7kg" referrerpolicy="no-referrer"/>
                </div>
                <div>{/*<div className="top-wrapper-middle">
                        <div className="in-page-nav-wrapper">
                                <p onClick={() => window.location.href = "/movement"} style={{marginRight: '4vw'}} className="in-page-nav-item">MOVEMENT</p>
                                <p onClick={() => window.location.href = "/still"} style={{marginRight: '4vw'}} className="in-page-nav-item">STILL</p>
                                <p onClick={() => window.location.href = "/shape"} style={{marginRight: '4vw'}} className="in-page-nav-item">SHAPE</p>
                                <p onClick={() => window.location.href = "/playhouse"} style={{marginRight: '4vw'}} className="in-page-nav-item">PLAYHOUSE</p>
                                <p onClick={() => window.location.href = "/about"} style={{fontFamily: "BoldNewSpirit"}} className="in-page-nav-item">ABOUT</p>
                                {checkout != undefined ? 
                                (checkout.lineItems != undefined && checkout.lineItems.length)> 0 ? 
                                <div className="in-page-nav-cart-link">
                                    <FontAwesomeIcon onClick={() => window.location.href = "/cart"} icon={faBagShopping} />
                                </div> : <React.Fragment /> : <React.Fragment />}
                            </div>
                    </div>
                <div className="top-wrapper-right">
                    <div className="page-word-wheel-wrapper">
                        <img className="page-word-wheel" src={PlayhouseWordWheel} alt="Word Wheel" />
                        <img className="page-word-wheel-icon" src={PlayhouseIcon} alt="Movement" />
                    </div>
                                </div>*/}</div>
            </div>
            {/*<div style={{background: "var(--about-color)"}} className="top-mobile-nav">
                <div className="mobile-nav-wrapper">
                    <p onClick={() => window.location.href = "/movement"} style={{marginRight: '7vw'}} className="in-page-nav-item">MOVEMENT</p>
                    <p onClick={() => window.location.href = "/still"} style={{marginRight: '7vw'}} className="in-page-nav-item">STILL</p>
                    <p onClick={() => window.location.href = "/shape"} style={{marginRight: '7vw'}} className="in-page-nav-item">SHAPE</p>
                    <p onClick={() => window.location.href = "/playhouse"} style={{marginRight: '7vw'}} className="in-page-nav-item">PLAYHOUSE</p>
                    <p onClick={() => window.location.href = "/about"} style={{fontFamily: "BoldNewSpirit"}} className="in-page-nav-item">ABOUT</p>
                    {checkout != undefined ? 
                        (checkout.lineItems != undefined && checkout.lineItems.length)> 0 ?
                        <div className="in-page-nav-cart-link">
                            <FontAwesomeIcon onClick={() => window.location.href = "/cart"} icon={faBagShopping} />
                        </div> : <React.Fragment /> : <React.Fragment />}
                </div>
            </div>*/}
            <div className="about-body-wrapper">
                <div className="about-text-wrapper">
                    <p className="about-paragraph">Based out of Los Angeles, Schmitt Studios is a multi-disciplinary design studio who explores different approaches of creating visual language within the entertainment, web3, design, and interactive worlds. At the head of the company, Claire Schmitt has defined and married simple chaos and modern nostalgia in her approach to both strategy and problem solving through creativity. </p>
                    <p className="featured-in-title">Featured In:</p>
                    <div className="featured-in-list">
                        <img className="featured-in-item" src="https://lh3.googleusercontent.com/b9KqRQ5k0PD0iBWobj5GDBY0eeEqTuV7kSUQYQWBHBKIfYnpC7U1-lfYa34mkxifjSZ4rvgfySbaGmcgWb2catuKcEYwMOys_GAqpDgEWvZz-bv1VIZkiEEe3g0CuO0CtxGOyJVIA6I=w2400"/>
                        <img className="featured-in-item" src="https://lh3.googleusercontent.com/VIS93q9Pho4jyunQJQmljtkB4dLO3PQXM68oqleqWPXxI0r7JPy4iOK0RZdIYAPrKHaCBqSf5oVhcoO2TUjAvYz4MIyzCErAfAf3jdeTFrKwKPlBFjnYbIX0teF8_TZE8PQPFnVDcZg=w2400"/>
                        <img className="featured-in-item" src="https://lh3.googleusercontent.com/QfV4w2WiZvUONS8EHNGAMsyZPueGPu35x2Eu24M80OjOyEgUMM7s9Lc0FkDuox8maz6P4NtlBFBSnEcuTsYH8F8at8OTUhl7DCdHLSbn0B-VD3Cxi-oe2xxf6K9kUaeTA_XTxuWIVDw=w2400"/>
                        <img className="featured-in-item" src="https://lh3.googleusercontent.com/bJrHTLwrntkGmmJjxUz8D47naSTtnDRtZEPH94zpMTHWRCuAeAhJ393JAo5Rac6qr9HU0WmeKjGbzLmMG3Wb5WCS6DlFsOK2U51vtNY6bbkJg4Y85LZFLmFweH9vQo2IZIqrVYoIzbY=w2400"/>
                        <img className="featured-in-item" src="https://lh3.googleusercontent.com/3mQekFZUUL05Ye8Q3YFpSjS40pv3Mvceq-cj0_ANkn10vLdK1Kn_ikX_dpwR6taDRWXEeZCtalHGfWg5zxrezMPaQLF3khV9bep4H0D-QHoIuAEEiRH4zOtz8XYnyBq7ABjwqPfhHFo=w2400"/>
                    </div>
                    <p className="about-subparagraph">For more information please contact: Claire@schmittstudios.co</p>
                </div>
            </div>
        </div>
    )
}

export default About; 