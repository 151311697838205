import React, {useState, useEffect, useContext} from 'react';
import ShapeWordWheel from '../assets/shape_wheel.svg';
import ShapeIcon from '../assets/shape_icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBagShopping } from '@fortawesome/free-solid-svg-icons'
import { ShopContext } from './shopContext'

function Shape() {
    const { fetchProductByHandle, addItemToCheckout , product, checkout } = useContext(ShopContext)

    return (
        <div id="page-wrapper">
            <div className="shape-top-wrapper">
                <div onClick={() => window.location.href = "/"} className="page-logo-wrapper">
                        <img style={{width: "100%", margin: "auto"}} src="https://lh3.googleusercontent.com/sOGO8IJR5ZC9Zs3GNYcFu5KjglIaFMOyvWMGFNNYRgs51YR2LnY_67A7raRFMbPZrQQwVRrgYmX-VG0R8F0MF5aDAH17Yu3Ij-JFdiZveZmdw0p3_5JoP8H1VeCx_3ziprad9uiq7kg" referrerpolicy="no-referrer"/>
                </div>
                <div className="top-wrapper-middle">
                        <div className="in-page-nav-wrapper">
                                <p onClick={() => window.location.href = "/movement"} style={{marginRight: '4vw'}} className="in-page-nav-item">MOVEMENT</p>
                                <p onClick={() => window.location.href = "/still"} style={{marginRight: '4vw'}} className="in-page-nav-item">STILL</p>
                                <p onClick={() => window.location.href = "/shape"} style={{marginRight: '4vw', fontFamily: "BoldNewSpirit"}} className="in-page-nav-item">SHAPE</p>
                                <p onClick={() => window.location.href = "/playhouse"} style={{marginRight: '4vw'}} className="in-page-nav-item">PLAYHOUSE</p>
                                <p onClick={() => window.location.href = "/about"} className="in-page-nav-item">ABOUT</p>
                                {checkout != undefined ? 
                                (checkout.lineItems != undefined && checkout.lineItems.length)> 0 ? 
                                <div className="in-page-nav-cart-link">
                                    <FontAwesomeIcon onClick={() => window.location.href = "/cart"} icon={faBagShopping} />
                                </div> : <React.Fragment /> : <React.Fragment />}
                        </div>
                    </div>
                <div className="top-wrapper-right">
                    <div className="page-word-wheel-wrapper">
                        <img className="page-word-wheel" src={ShapeWordWheel} alt="Word Wheel" />
                        <img className="page-word-wheel-icon" src={ShapeIcon} alt="Shape" />
                    </div>
                </div>
            </div>
            <div style={{background: "var(--shape-color)"}} className="top-mobile-nav">
                <div className="mobile-nav-wrapper">
                    <p onClick={() => window.location.href = "/movement"} style={{marginRight: '7vw'}} className="in-page-nav-item">MOVEMENT</p>
                    <p onClick={() => window.location.href = "/still"} style={{marginRight: '7vw'}} className="in-page-nav-item">STILL</p>
                    <p onClick={() => window.location.href = "/shape"} style={{marginRight: '7vw', fontFamily: "BoldNewSpirit"}} className="in-page-nav-item">SHAPE</p>
                    <p onClick={() => window.location.href = "/playhouse"} style={{marginRight: '7vw'}} className="in-page-nav-item">PLAYHOUSE</p>
                    <p onClick={() => window.location.href = "/about"} className="in-page-nav-item">ABOUT</p>
                    {checkout != undefined ? 
                        (checkout.lineItems != undefined && checkout.lineItems.length)> 0 ?
                        <div className="in-page-nav-cart-link">
                            <FontAwesomeIcon onClick={() => window.location.href = "/cart"} icon={faBagShopping} />
                        </div> : <React.Fragment /> : <React.Fragment />}
                </div>
            </div>
            <div style={{backgroundColor: "var(--shape-color)"}} className="page-slogan-wrapper">
                <p className="page-slogan">Tour Visuals, Art Direction, Graphic Design, Merch & More</p>
            </div>
            <div className="shape-body-wrapper">
                <div style={{paddingTop: "4vh"}} className="shape-photo-row-first">
                    <div onClick={() => window.location.href = "/shape/diplo-x-crocs"} className="shape-photo-big-left">
                        <img className="shape-photo-big" src="https://lh3.googleusercontent.com/7IdxNlsdH2Ai4C-edOrFmK_kT-UEmgH0uwNG2NNWivgXu4RIxeqZz3BMTBobzGJ8UrM3sa5XTMKbCOBSuAhDsE8UfGT8LA7MuuQB-U7HqQ5vNMoZ8nMI-YHGVptj2jv763jd1S0PmvI" referrerpolicy="no-referrer"/>
                        <p className="shape-img-text">DIPLO X CROCS</p>
                    </div>
                    <div style={{marginLeft: 'auto', marginRight: '0'}} className="shape-photo-small-wrapper">
                        <div onClick={() => window.location.href = "/shape/louis-vuiton-city-of-stars"} style={{marginBottom: "auto", marginLeft: "auto"}} className="shape-photo-smallest">
                            <img className="shape-photo-small" src="https://lh3.googleusercontent.com/BAVs_uivq5pDo_1cEuG0VdsY5Gxqo6mrpxk5tUHu2NoVnGlfbaUUZg6dtCy5bcDSFVJluFqXC22vYXmqzDcWnPxNcDMEK6XYaCRzKcQmDP55lePhVxLBHb4edqg8-QVdkY4qFOk8WC0" referrerpolicy="no-referrer"/>
                            <p className="shape-img-text">LOUIS VUITTON</p>
                        </div>
                        <div onClick={() => window.location.href = "/shape/mutual-friends-rr"} style={{marginTop: "auto", marginLeft: "auto", marginRight: '0', marginBottom: '0'}} className="shape-photo-smallest">
                            <img className="shape-photo-small" src="https://lh3.googleusercontent.com/i98UbCUZWomE7BTF5M1sizIJZkVFEwskFfdFVc4p3qQicvlelOf8BU4J1sgsdbUEsNqvJodQQWW35-O5if_Q4jOoxAqWR3j-tV1axufnL4iY1mK8jcZG0u5hN6CpWhfsuNw8fnFRSq4" referrerpolicy="no-referrer"/>
                            <p className="shape-img-text">MUTUAL FRIENDS</p>
                        </div>
                    </div>
                </div>
                <div className="shape-photo-row">
                    <div style={{marginRight: 'auto', marginLeft: '0'}} className="shape-photo-small-wrapper">
                        <div onClick={() => window.location.href = "/shape/dove-cameron-tour"} style={{marginBottom: "auto", marginLeft: "auto"}} className="shape-photo-smallest">
                            <img  className="shape-photo-small" src="https://lh3.googleusercontent.com/jaacVSKBfw5R3L2IYKcf4pi2Gt7ZloPfkJ2oDSxr6cjZ4rx7CNoTgWPrZ5apNX-DmCoSV9Amk07_qiPAswl8RX5gsXcY19bT7TmtWhELYWEiyPuN0-Kmg2L_kGX5RrmWuiFFzLHgzbI" referrerpolicy="no-referrer"/>
                            <p className="shape-img-text">DOVE CAMERON</p>
                        </div>
                        <div onClick={() => window.location.href = "/shape/remi-wolf-tour-visuals"} style={{marginTop: "auto", marginLeft: "0", marginRight: 'auto', marginBottom: '0'}} className="shape-photo-smallest">
                            <img  className="shape-photo-small" src="https://lh3.googleusercontent.com/uhjFvvBCDkBpT2J48R5Au7SoBb3tfZuY2B3FZ-yD67xyrga0RXUmTYGqixX5mHkox_5vgKIDn_K8-CUpJDS15l7IovXwNzjsAlgrjOeiofpOpKPe31tFrqOGnLs9ZIjSZYydJVy10m4" referrerpolicy="no-referrer"/>
                            <p className="shape-img-text">REMI WOLF</p>
                        </div>
                    </div>
                    <div onClick={() => window.location.href = "/shape/bill-nye"} className="shape-photo-big-right">
                        <img className="shape-photo-big" src="https://lh3.googleusercontent.com/Y9f_g5Z5V9x_sVrts14dOo1zh2ArwIn8xG6xZoIpLHyku6K0kzSzjymeOwRqYNFEfAvthWoLu-XPNqZTVLIgndI2u3gZcnA7vSLBpDk9bL7BHJP44-yu4QzfBOI5nQAB5V_PklTTOnY" referrerpolicy="no-referrer"/>
                        <p className="shape-img-text">BILL NYE</p>
                    </div>
                </div>
                <div className="shape-photo-row">
                    <div onClick={() => window.location.href = "/shape/fmwl"} className="shape-photo-big-left">
                        <img className="shape-photo-big" src="https://lh3.googleusercontent.com/Xt35qkuduY8pDtlhBrykKz_ZjSB-Edaj3_nNWO6B_YGmGIbCdYPogG_PgG91uwoq6_rqCPJh41GbQ5i2J8U_Y-nU6m-U5zMrXQDBiQh35rk-GSaK26-YL3gpvmX6iFtaoCCSrBP138I" referrerpolicy="no-referrer"/>
                        <p className="shape-img-text">QUINN XCII</p>
                    </div>
                    <div style={{marginLeft: 'auto', marginRight: '0'}} className="shape-photo-small-wrapper">
                        <div onClick={() => window.location.href = "/shape/cal-roll-the-credits"} style={{marginBottom: "auto", marginLeft: "auto"}} className="shape-photo-smallest">
                            <img style={{marginBottom: "auto", marginLeft: "auto"}} className="shape-photo-small" src="https://lh3.googleusercontent.com/97aUzgS8SllF05U4eIqLwJz1I9KOCizEe6BqdnfvO3q4uzVjaz1Ctw_0TFHdiqzKmyrTwZaYDNJb9zBps2ekF2rRwzaJ80-mQu-0SismUuJ6GAAUVRpGDMGnX9R749a4851uksC0_o8" referrerpolicy="no-referrer"/>
                            <p className="shape-img-text">CAL</p>
                        </div>
                        <div onClick={() => window.location.href = "/shape/jessie-murph-sobriety-merch"} style={{marginTop: "auto", marginLeft: "auto", marginRight: '0', marginBottom: '0'}} className="shape-photo-smallest">
                            <img style={{marginTop: "auto", marginLeft: "auto", marginRight: '0', marginBottom: '0', objectFit: "cover"}} className="shape-photo-small" src="https://lh3.googleusercontent.com/tx-CI-HowdH4cDkLAUGInoIiC3f8n1aGCdmTOy6Np9G-Y0RZXZFe5dwGqVmUheLjLgg7lmNhWNHAQOGjFKgtC6ZDTgtn4VGhMSjaje7qpS7JK7wJgLY5oILUdCBGf7bohsldRdvvYlY" referrerpolicy="no-referrer"/>
                            <p className="shape-img-text">JESSIE MURPH</p>
                        </div>
                    </div>
                </div>
                <div className="shape-photo-row">
                    <div style={{marginRight: 'auto', marginLeft: '0'}} className="shape-photo-small-wrapper">
                        <div onClick={() => window.location.href = "/shape/gashi-dont-pass-on-love"} style={{marginBottom: "auto", marginLeft: "auto"}} className="shape-photo-smallest">
                            <img style={{marginBottom: "auto", marginLeft: "auto"}} className="shape-photo-small" src="https://lh3.googleusercontent.com/WGIUTpbFjBEfxG5IepWNsuLwr-q5WgfPn-SyFMsXzHkUAX76y8YDCMiicPpStAiueMBzBASZ9KL_I5iQXBNoo7dZL1Jjj906TvYGPAs5iAGCwYlvMeT_vodwLwybHkfcGmPzQBf_9m4" referrerpolicy="no-referrer"/>
                            <p className="shape-img-text">GASHI</p>
                        </div>
                        <div onClick={() => window.location.href = "/shape/gashi-sleeping-on-my-left"} style={{marginTop: "auto", marginLeft: "auto", marginRight: '0', marginBottom: '0'}} className="shape-photo-smallest">
                            <img style={{marginTop: "auto", marginLeft: "0", marginRight: 'auto', marginBottom: '0'}} className="shape-photo-small" src="https://lh3.googleusercontent.com/9F21xj1y0Nn6r4lJYH_bF42melVT0CJ4pwiWYwn-e0LPL032ESiodRabmi5NTdTy6-V221UJRXVVlBJZYBmcU7MN90UpOkNRJODwnmU4tpKnzKfnJ01f6Y6chaC-8lDfrz9ctSyJZC4" referrerpolicy="no-referrer"/>
                            <p className="shape-img-text">GASHI</p>
                        </div>
                    </div>
                    <div onClick={() => window.location.href = "/shape/matt-hansen-bowm"} className="shape-photo-big-right">
                        <img className="shape-photo-big" src="https://lh3.googleusercontent.com/YCNRRjLl_SfhsC3BMMexj5W6TkUwSWC11yVG00xheoXebRAz6Kf7YTuu0Zwl2HMP5WC9iOiSDYqcIAm-wfQzPPfw-BdTwM3Sx8kRt8LvToHW1MlqeX4sPOK1QPgbWKKRI1KkIMQB4ns" referrerpolicy="no-referrer"/>
                        <p className="shape-img-text">MATT HANSEN</p>
                    </div>
                </div>
                <div className="shape-photo-row">
                    <div onClick={() => window.location.href = "/shape/pp-cocaine-krispy-kreme"} className="shape-photo-big-left">
                        <img className="shape-photo-big" src="https://lh3.googleusercontent.com/S76bzrh3drk7TqZ141iarezx2-bV7_afwZ9ihsdBgXNd9BtDQztE_NTQkyv-kfKW9h7ptdE470tb1RWF10zMMiRws-zuBWCrjKdgCjp6z3BV81PreFkl9wwfBBRm4NBk2vK10TBW8ZM" referrerpolicy="no-referrer"/>
                        <p className="shape-img-text">P.P COCAINE</p>
                    </div>
                    <div style={{marginLeft: 'auto', marginRight: '0'}} className="shape-photo-small-wrapper">
                        <div onClick={() => window.location.href = "/shape/pp-cocaine-level-up"} style={{marginBottom: "auto", marginLeft: "auto"}} className="shape-photo-smallest">
                            <img style={{marginBottom: "auto", marginLeft: "auto"}} className="shape-photo-small" src="https://lh3.googleusercontent.com/QX5fpJaMazAbeM9CoI_Pe8zMTAWuo6KMVD67lRcjaZQQ201--Sj0Kbbp8LCmKNwXi_gYaYEdwn4MumpiPTTk7i546AGy-9kHb1LMNJP6F3qZrI6H_c121ylojyXZEoMlC186pqkd9Ac" referrerpolicy="no-referrer"/>
                            <p className="shape-img-text">P.P COCAINE</p>
                        </div>
                        <div onClick={() => window.location.href = "/shape/troop"} style={{marginTop: "auto", marginLeft: "auto", marginRight: '0', marginBottom: '0'}} className="shape-photo-smallest">
                            <img style={{marginTop: "auto", marginLeft: "auto", marginRight: '0', marginBottom: '0', objectFit: "cover"}} className="shape-photo-small" src="https://lh3.googleusercontent.com/Rp5ohcpnitD_J0DWZ3jJTmvfvX55YadqmM7G6j0WsTxn0cNtuW2IEObZy9cq-fKx8-hkDrUehXUy-G_C_PKgpUPX84zzWakZ2L_mAAMfSs2s2CTTJxt2a_fp1n1uc2sL1rHA3QbAe_I" referrerpolicy="no-referrer"/>
                            <p className="shape-img-text">TROOP COCKTAILS</p>
                        </div>
                    </div>
                </div>
                <div style={{height: "fit-content"}} className="shape-photo-row">
                    <div onClick={() => window.location.href = "/shape/rosie-darling-wmd"} className="shape-photo-half-left">
                        <img style={{margin: "auto", marginTop: "0"}} className="shape-photo-half" src="https://lh3.googleusercontent.com/jneReXDOkp5LO7t-vvSoyEWn_7DHh-aJVTO5A8qwRGkHsvVFmdEvGrf5XsuxAMth6rZElyzeNTMWGbrnx4cqEwv9zYwcYN_UObzIZfmwLp-Dt1M5vhbqgSvUv2hk3q1UQP_s5gCf2bg" referrerpolicy="no-referrer"/>
                        <p className="shape-img-text">ROSIE DARLING</p>
                    </div>
                    <div onClick={() => window.location.href = "/shape/rosie-darling-coping"} className="shape-photo-half-right">
                        <img style={{margin: "auto", marginTop: "0"}} className="shape-photo-half" src="https://lh3.googleusercontent.com/j8vvdqqmbNNDvv_M03QkopH34zUtvt2g9VW4NSLLAFiZj1d1z9kbHJgPu06-lmPGcpmhcc10GBZKHh1J3YcVjfpy7Pku0X858CFgJB6TqdPi0Pv0rqtMi4uiy3VymLNhFWrKqQKmMvo" referrerpolicy="no-referrer"/>
                        <p className="shape-img-text">ROSIE DARLING</p>
                    </div>
                </div>
                {/*<div className="shape-photo-row">
                    <div onClick={() => window.location.href = "/shape/pp-cocaine-level-up"} className="shape-photo-big-left">
                        <img className="shape-photo-big" src="https://lh3.googleusercontent.com/S76bzrh3drk7TqZ141iarezx2-bV7_afwZ9ihsdBgXNd9BtDQztE_NTQkyv-kfKW9h7ptdE470tb1RWF10zMMiRws-zuBWCrjKdgCjp6z3BV81PreFkl9wwfBBRm4NBk2vK10TBW8ZM" referrerpolicy="no-referrer"/>
                        <p className="shape-img-text">TEST TEXT</p>
                    </div>
                    <div style={{marginLeft: 'auto', marginRight: '0'}} className="shape-photo-small-wrapper">
                        <div onClick={() => window.location.href = "/shape/anthony-dlt-anyone-else"} style={{marginBottom: "auto", marginLeft: "auto"}} className="shape-photo-smallest">
                            <img style={{marginBottom: "auto", marginLeft: "auto"}} className="shape-photo-small" src="https://lh3.googleusercontent.com/5wO1YJ7j5k5FdYEP9RwwFzDLQlDAIjMr5C2ppW5yJwwO1k1X8bVH0G5KshhXl5jcZhclfn1VVguipFuAMROlSmjdOfOxLb3vKcxcg8HrH0qgbcCvkb1mEEHsChqe84LYknMaXQt3Iow" referrerpolicy="no-referrer"/>
                            <p style={{fontSize: "32px"}} className="shape-img-text">ANTHONY DE LA TORRE</p>
                        </div>
                        <div onClick={() => window.location.href = "/shape/anthony-dlt-trust-fall"} style={{marginTop: "auto", marginLeft: "auto", marginRight: '0', marginBottom: '0'}} className="shape-photo-smallest">
                            <img style={{marginTop: "auto", marginLeft: "auto", marginRight: '0', marginBottom: '0', objectFit: "cover"}} className="shape-photo-small" src="https://lh3.googleusercontent.com/9EN_ubVWQ72OEPITP-kWLTwY5_J7gMvyBGfTiAs4KRgUrp80-C35QSNtGg9IyIySYeD2rJcEqU-gHVoyWba3ZsKyY7x1YXcAywllax99q1uuS1dCupMUZE7yKTsub8ItvGxV2DGTLCE" referrerpolicy="no-referrer"/>
                            <p style={{fontSize: "32px"}} className="shape-img-text">ANYTHONY DE LA TORRE</p>
                        </div>
                    </div>
                </div>*/}
            </div>
        </div>
    )
}

export default Shape; 