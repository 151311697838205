import React, {useState, useEffect, useContext} from 'react';
import MovementWordWheel from '../assets/movement_wheel.svg';
import MovementIcon from '../assets/movement_icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBagShopping } from '@fortawesome/free-solid-svg-icons'
import { ShopContext } from './shopContext'

function Movement() {
    const { fetchProductByHandle, addItemToCheckout , product, checkout } = useContext(ShopContext)

    const setHovering = (idName) => {
        let movement_item = document.getElementById(idName);
        movement_item.style.display = "flex";
    }

    const removeHovering = (idName) => {
        let movement_item = document.getElementById(idName);
        movement_item.style.display = "none";
    }

    return (
        <div id="page-wrapper">
            <div className="movement-top-wrapper">
                <div onClick={() => window.location.href = "/"} className="page-logo-wrapper">
                    <img style={{width: "100%", margin: "auto"}} src="https://lh3.googleusercontent.com/sOGO8IJR5ZC9Zs3GNYcFu5KjglIaFMOyvWMGFNNYRgs51YR2LnY_67A7raRFMbPZrQQwVRrgYmX-VG0R8F0MF5aDAH17Yu3Ij-JFdiZveZmdw0p3_5JoP8H1VeCx_3ziprad9uiq7kg" referrerpolicy="no-referrer"/>
                </div>
                <div className="top-wrapper-middle">
                    <div className="in-page-nav-wrapper">
                            <p onClick={() => window.location.href = "/movement"} style={{marginRight: '4vw', fontFamily: "BoldNewSpirit"}} className="in-page-nav-item">MOVEMENT</p>
                            <p onClick={() => window.location.href = "/still"} style={{marginRight: '4vw'}} className="in-page-nav-item">STILL</p>
                            <p onClick={() => window.location.href = "/shape"} style={{marginRight: '4vw'}} className="in-page-nav-item">SHAPE</p>
                            <p onClick={() => window.location.href = "/playhouse"} style={{marginRight: '4vw'}} className="in-page-nav-item">PLAYHOUSE</p>
                            <p onClick={() => window.location.href = "/about"} className="in-page-nav-item">ABOUT</p>
                            {checkout != undefined ? 
                                (checkout.lineItems != undefined && checkout.lineItems.length)> 0 ? 
                                <div className="in-page-nav-cart-link">
                                    <FontAwesomeIcon onClick={() => window.location.href = "/cart"} icon={faBagShopping} />
                                </div> : <React.Fragment /> : <React.Fragment />}
                    </div>
                </div>
                <div className="top-wrapper-right">
                    <div className="page-word-wheel-wrapper">
                        <img className="page-word-wheel" src={MovementWordWheel} alt="Word Wheel" />
                        <img className="page-word-wheel-icon" src={MovementIcon} alt="Movement" />
                    </div>
                </div>
            </div>
            <div style={{background: "var(--movement-color)"}} className="top-mobile-nav">
                <div className="mobile-nav-wrapper">
                    <p onClick={() => window.location.href = "/movement"} style={{marginRight: '7vw', fontFamily: "BoldNewSpirit"}} className="in-page-nav-item">MOVEMENT</p>
                    <p onClick={() => window.location.href = "/still"} style={{marginRight: '7vw'}} className="in-page-nav-item">STILL</p>
                    <p onClick={() => window.location.href = "/shape"} style={{marginRight: '7vw'}} className="in-page-nav-item">SHAPE</p>
                    <p onClick={() => window.location.href = "/playhouse"} style={{marginRight: '7vw'}} className="in-page-nav-item">PLAYHOUSE</p>
                    <p onClick={() => window.location.href = "/about"} className="in-page-nav-item">ABOUT</p>
                    {checkout != undefined ? 
                        (checkout.lineItems != undefined && checkout.lineItems.length)> 0 ?
                        <div className="in-page-nav-cart-link">
                            <FontAwesomeIcon onClick={() => window.location.href = "/cart"} icon={faBagShopping} />
                        </div> : <React.Fragment /> : <React.Fragment />}
                </div>
            </div>
            <div style={{backgroundColor: "var(--movement-color)"}} className="page-slogan-wrapper">
                <p className="page-slogan">Stories Through Video</p>
            </div>
            <div className="movement-body-wrapper">
                <div className="movement-body-row">
                    <div onClick={() => window.location.href = "/movement/the-chainsmokers-high"} onMouseEnter={() => setHovering("chainsmokers-1")} onMouseLeave={() => removeHovering("chainsmokers-1")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-left" src="https://lh3.googleusercontent.com/bPqrWqo8Be47AcsROKH53_1tLCyl7EFx20p4tqbdg04OP7MHq8ZnK7X3TfK-C3JhE0DUav1JhD7t1NXEUD3fBDSoKke41G3xAHdcAVRho4Nb15-lL4spgDZ3YvLMX8OUvOz_mug9G30" referrerpolicy="no-referrer"/>
                        <div id="chainsmokers-1" className="movement-item-text-left">
                            <p>THE CHAINSMOKERS "HIGH" BEHIND THE SCENES</p>
                        </div>
                    </div>
                    {/*<iframe className="movement-iframe movement-iframe-left" width="560" height="315" src="https://www.youtube.com/embed/Gr68wZs2oqU?modestbranding=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>*/}
                    {/*<iframe className="movement-iframe movement-iframe-right" width="560" height="315" src="https://www.youtube.com/embed/Ds56h6WYmN0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>*/}
                    <div onClick={() => window.location.href = "/movement/jessie-murph-whtr"} onMouseEnter={() => setHovering("whtr")} onMouseLeave={() => removeHovering("whtr")} className="movement-item-wrapper">
                       <img className="movement-iframe movement-iframe-right" src="https://lh3.googleusercontent.com/m7uYxz_GXM4l1R9I993aUnSeX7WkXI2yIeBhPlHbHEjBq5kNCX-SQi6Lx04VjtiyJrR7k_pHjIOS2nQKMJKgQMC6qB96j8TgcuwI8xvOQFvccPZYrHXyB1P22xBJcOXLcRx5asu3jw" referrerpolicy="no-referrer" />
                       <div id="whtr" className="movement-item-text-right">
                           <p>JESSIE MURPH "WHAT HAPPENED TO RYAN"</p>
                       </div>
                   </div>
                </div>
                <div className="movement-body-row">
                    <div onClick={() => window.location.href = "/movement/jessie-murph-wyai"} onMouseEnter={() => setHovering("wyai")} onMouseLeave={() => removeHovering("wyai")} className="movement-item-wrapper">
                       <img className="movement-iframe movement-iframe-left" src="https://lh3.googleusercontent.com/EF-N7fDIE3TO7G_Yyyrludw9nbNYe3lxXyIfLzxuzdYKHyZFtepW-KJv0xcVV8lA5sv_ofS2I3Z2pKfxQ7j4lMa-gf86mrIGchd3iyHHsSz8htuKO0q41lRQoWgEVBxGpR-OhPzceWU" referrerpolicy="no-referrer" />
                       <div id="wyai" className="movement-item-text-left">
                           <p>JESSIE MURPH "WHILE YOU'RE AT IT"</p>
                       </div>
                   </div>
                    <div onClick={() => window.location.href = "/movement/jessie-murph-wdyg"} onMouseEnter={() => setHovering("wdyg")} onMouseLeave={() => removeHovering("wdyg")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-right" src="https://lh3.googleusercontent.com/_l1cZ1sFRA27D8NeliCCQmcrONitVQ51D-t8llcfCm8MpSZn7jFqc1sOMO_ZrqjSBRGoQq-roE4n-5fav4V9tvNeiZXlqgr9BJb-Nz5ikBRkt1IT0BZ31ta8I1ocI_uOMkQg4wOYQbM" referrerpolicy="no-referrer" />
                        <div id="wdyg" className="movement-item-text-right">
                            <p>JESSIE MURPH "WHERE DO YOU GO"</p>
                        </div>
                    </div>
                </div>
                <div className="movement-body-row">
                   <div onClick={() => window.location.href = "/movement/the-chainsmokers-i-love-u"} onMouseEnter={() => setHovering("iloveu")} onMouseLeave={() => removeHovering("iloveu")} className="movement-item-wrapper">
                       <img className="movement-iframe movement-iframe-left" src="https://lh3.googleusercontent.com/_2fMuA5XuXGC3eiv9nJN10TcBdQ610kmIbgraUkesYiXwiv4CHoQLeeTsufW2Z0ZKqYOUTHzRtqCDUNEBbIe9h1JSZIIW7mekHI4Q0-2EhpB_YMddir_fFH1BAkwhm5cy0vLL_Afcbk" referrerpolicy="no-referrer" />
                       <div id="iloveu" className="movement-item-text-left">
                           <p>THE CHAINSMOKERS "I LOVE U" BEHIND THE SCENES</p>
                       </div>
                   </div>
                    <div onClick={() => window.location.href = "/movement/quinnxcii-am-i-high-rn"} onMouseEnter={() => setHovering("aihrn")} onMouseLeave={() => removeHovering("aihrn")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-right" src="https://lh3.googleusercontent.com/H7YrLTkmA0SyJrQWlQNoAzP_2F2-LQD698pv1VD2tfd1L0LAFNnZwBQWJgGkCZuKGt2ZjdmLJe2mNiZeHlZpiQvhidqj3LxCaDd_Eo8bu07r6BZ9p4516gfrIvfcRpErUIqG2t0C3Ng" referrerpolicy="no-referrer" />
                        <div id="aihrn" className="movement-item-text-right">
                            <p>QUINN XCII "AM I HIGH RN" (LYRIC VIDEO)</p>
                        </div>
                    </div>
                </div>
                <div className="movement-body-row">
                   <div onClick={() => window.location.href = "/movement/jessie-murph-if-i-died-last-night"} onMouseEnter={() => setHovering("ifidiedln")} onMouseLeave={() => removeHovering("ifidiedln")} className="movement-item-wrapper">
                       <img className="movement-iframe movement-iframe-left" src="https://lh3.googleusercontent.com/nan3Rucv5FLQZA4h22EfGs34nBQIFO6fxHoiD85AAzYiZuoagbgS8aChEatrcHbLUzzlax8cOBj2gQCNH-AZoqOV2HAXOaLC-LNlPmU5fPgBG_kJqJF2KVlgok8rsvtBuQym1uvdTZE" referrerpolicy="no-referrer" />
                       <div id="ifidiedln" className="movement-item-text-left">
                           <p>JESSIE MURPH "IF I DIED LAST NIGHT"</p>
                       </div>
                   </div>
                    <div onClick={() => window.location.href = "/movement/quinn-xcii-the-peoples-champ"} onMouseEnter={() => setHovering("peopleschamp")} onMouseLeave={() => removeHovering("peopleschamp")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-right" src="https://lh3.googleusercontent.com/padqKbva8PriqYh_TBbzgNCvUupp-CwmiLq0hq-rxP7FsCUPSuCtFAkLL9qKEfWZm-zBSO9PMhJ8xflNXZyhDwwud_S5BaitdObu-4xogmFloqjGoZZErt8YtBRCy_jxEt4AQopPkcE" referrerpolicy="no-referrer" />
                        <div id="peopleschamp" className="movement-item-text-right">
                            <p>QUINN XCII "THE PEOPLE'S CHAMP"</p>
                        </div>
                    </div>
                </div>
                <div className="movement-body-row">
                   <div onClick={() => window.location.href = "/movement/jessie-murph-they-leave"} onMouseEnter={() => setHovering("theyleave")} onMouseLeave={() => removeHovering("theyleave")} className="movement-item-wrapper">
                       <img className="movement-iframe movement-iframe-left" src="https://lh3.googleusercontent.com/EvtMaSgD2WF71NpxGIjrXIqKVAB0wiINvR6yaqtqq0VCC5RqD9dOcG8oaCWIJ72NRj7b2lqMqYPLnab4ENnyBPmNQ1kxPgQoxuTo8NTU5_5a7Bj4xQ-RLtW3Qh7ygfUny4RLb0bOnSE" referrerpolicy="no-referrer" />
                       <div id="theyleave" className="movement-item-text-left">
                           <p>JESSIE MURPH "THEY LEAVE"</p>
                       </div>
                   </div>
                    <div onClick={() => window.location.href = "/movement/carly-rose-brokenhearted"} onMouseEnter={() => setHovering("brokenhearted")} onMouseLeave={() => removeHovering("brokenhearted")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-right" src="https://lh3.googleusercontent.com/P3CFcGW8j-kRdyKdN_wC_qW26R4j2G51mzS-6aa_vRc_I-yXNb1y2wV_Vl6P6cpEyNb9TSc4-Urcx_PwWqCrV9DSrVrDXjpn3rBicoEvW9V-3Q1wjUQ_m57X3-SmZdPj4SegYA30NgY" referrerpolicy="no-referrer" />
                        <div id="brokenhearted" className="movement-item-text-right">
                            <p>CARLY ROSE "BROKENHEARTED"</p>
                        </div>
                    </div>
                </div>
                <div className="movement-body-row">
                    <div onClick={() => window.location.href = "/movement/peter-mcpoland-slow-down"} onMouseEnter={() => setHovering("slowdown")} onMouseLeave={() => removeHovering("slowdown")} className="movement-item-wrapper">
                       <img className="movement-iframe movement-iframe-left" src="https://lh3.googleusercontent.com/baKL7oBu_fpV9fkjJlkP8kmwhWETpygvg9P3NAUX6-feqOH9mOEOYNpbeYmZcg3suiSiLi_kBb71PY2-2KWXA_vNUK8nERHbHdiY6lJsmyYsjP9wwCHX8Sbco0xXNHC5y2Pk2HvHDJ4" referrerpolicy="no-referrer" />
                       <div id="slowdown" className="movement-item-text-left">
                           <p>PETER MCPOLAND "SLOW DOWN" (OFFICIAL VIDEO)</p>
                       </div>
                   </div>
                    <div onClick={() => window.location.href = "/movement/quinnxcii-u-and-us"} onMouseEnter={() => setHovering("uandus")} onMouseLeave={() => removeHovering("uandus")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-right" src="https://lh3.googleusercontent.com/2MhamkqahodE4nbeYOqOeIuXjIVOTPcA2klWXcqcoIIgYHOJv0boiyhPBvcs_ZMaWYpT9O7nIYTTRJqrQTIifxu-w9N1Gc1y672w0f6_tGUid7r_Y8ImT2PR_NhBbqANMOBwsa072Bw" referrerpolicy="no-referrer"/>    
                        <div id="uandus" className="movement-item-text-right">
                            <p>QUINN XCII "U & US" (MUSIC VIDEO)</p>
                        </div>
                    </div>
                    {/*<iframe className="movement-iframe movement-iframe-left" width="560" height="315" src="https://www.youtube.com/embed/BnOaL4PFSL8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe className="movement-iframe movement-iframe-right" width="560" height="315" src="https://www.youtube.com/embed/4KvKCXBReys" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>*/}
                    
                    {/*<iframe className="movement-iframe movement-iframe-left" width="560" height="315" src="https://www.youtube.com/embed/woNkdVEwM8o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe className="movement-iframe movement-iframe-right" width="560" height="315" src="https://www.youtube.com/embed/Ds56h6WYmN0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>*/}
                </div>
                <div className="movement-body-row">
                    <div onClick={() => window.location.href = "/movement/blackbear-if-i-were-u"} onMouseEnter={() => setHovering("iiwu")} onMouseLeave={() => removeHovering("iiwu")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-left" src="https://lh3.googleusercontent.com/aORMy5ZU5r5Ra3b-H8WKmgWmHEoF2-xhun_eVQMkJY92HabHNUHmTYiDYFlMNeNZ2jmlBmJjApa-x_oXeZub_jxo5e9ni1dDmvEohG4thq9d1fS6VCzE0cloOnYabBDbr1dddyfagOo" referrerpolicy="no-referrer"/>
                        <div id="iiwu" className="movement-item-text-left">
                            <p>BLACKBEAR "IF I WERE U" (LYRIC VIDEO)</p>
                        </div>
                    </div>
                    <div onClick={() => window.location.href = "/movement/the-chainsmokers-live-from-alexs-kitchen"} onMouseEnter={() => setHovering("kitchen")} onMouseLeave={() => removeHovering("kitchen")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-right" src="https://lh3.googleusercontent.com/MdGeXGG3d8lZpsw8Dg77HtkpZSxQ1nBx64ic4Yz64p9RK9Pvo2db67P9nQUitSHJOOuVhmfrd9kA8VGwB6KZSIBBAx3pn8oSma4RrnjcVciRk2Maqf7IiyejWdSV74pZ_bHI0U8XIMI" referrerpolicy="no-referrer" />
                        <div id="kitchen" className="movement-item-text-right">
                            <p>THE CHAINSMOKERS "LIVE FROM ALEX'S KITCHEN"</p>
                        </div>
                    </div>
                    
                </div>
                <div className="movement-body-row">
                    <div onClick={() => window.location.href = "/movement/max-drazen-caffeine"} onMouseEnter={() => setHovering("caffeine")} onMouseLeave={() => removeHovering("caffeine")} className="movement-item-wrapper">
                       <img className="movement-iframe movement-iframe-left" src="https://lh3.googleusercontent.com/848UGS7Cp83Cv1nx6dg3uzqh2Bp2fT0zRRZU098H02Kj-g2IP3E0KjPcbzGlkethcaoGjRn-ih1eUxwwoMHd6FnxlDiTz4J9vOybyYEw4p5LEkeWLYzy368rMtcYsgIp9ueRVZCAsXc" referrerpolicy="no-referrer" />
                       <div id="caffeine" className="movement-item-text-left">
                           <p>MAX DRAZEN “CAFFEINE</p>
                       </div>
                    </div>
                    <div onClick={() => window.location.href = "/movement/lost-kings-under-the-influence"} onMouseEnter={() => setHovering("undertheinfluence")} onMouseLeave={() => removeHovering("undertheinfluence")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-right" src="https://lh3.googleusercontent.com/gPxHqsHIK60TcdzS7BEChvx8i94gi6eVdxCryIqSFBA0arSEuDvKAdDkc20jkC5tqRrPuO0XEKGxm3e6MCaIkhdC68tLuoC5eeRlWNEt4QAgITNcbE_IxzD9XNvo-tNjLgKxPUZg2HU" referrerpolicy="no-referrer"/>
                        <div id="undertheinfluence" className="movement-item-text-right">
                            <p>LOST KINGS "UNDER THE INFLUENCE"</p>
                        </div>
                    </div>
                </div>
                <div className="movement-body-row">
                    <div onClick={() => window.location.href = "/movement/pride-x-pandora"} onMouseEnter={() => setHovering("pride")} onMouseLeave={() => removeHovering("pride")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-left" src="https://lh3.googleusercontent.com/Y5iGw1oovBWYnyIOnecQfk23Xx0fDX3CR6aP6IkZkLEC4A2dGz8KV8bnL_ZnmWWcldXpR0xs0LLhNLw0hR838PMGSyJq8zyYIbNL84A21S3sAkPkBL_D3-Qf6qvt7fCfaWNykxDuYfY" referrerpolicy="no-referrer"/>
                        <div id="pride" className="movement-item-text-left">
                            <p>PRIDE X PANDORA</p>
                        </div>
                    </div>
                    <div onClick={() => window.location.href = "/movement/jessie-murph-how-could-you"} onMouseEnter={() => setHovering("howcouldyou")} onMouseLeave={() => removeHovering("howcouldyou")} className="movement-item-wrapper">
                       <img className="movement-iframe movement-iframe-right" src="https://lh3.googleusercontent.com/bImh7dql0-RiQGdniehippGsA4Asxslvms6aIDI3mLzUSxNZeHWzNN4sLR2qPxbxuqY1wU4Jyv4oZSwZBo6TOBLOEzhA7-QZtjolbrEkJ3_PTv_EMGN19plVXj9EWqRQeoU0EC46X_o" referrerpolicy="no-referrer" />
                       <div id="howcouldyou" className="movement-item-text-right">
                           <p>JESSIE MURPH "HOW COULD YOU"</p>
                       </div>
                   </div>
                </div>
                <div className="movement-body-row">
                    <div onClick={() => window.location.href = "/movement/matt-hansen-the-nights"} onMouseEnter={() => setHovering("thenights")} onMouseLeave={() => removeHovering("thenights")} className="movement-item-wrapper">
                       <img className="movement-iframe movement-iframe-left" src="https://lh3.googleusercontent.com/ICKuaccix5rFWC819V86bYhdhOQGs3yV682V05hpLkemXjUMhbiVBuzrzYSB65WfbN9YUHWlMW0bR12jaG3zUq6tz7C00svdW3IQYaoc0kdJVEqszhn4zBzbamf_IEvOnYQQ04085OY" referrerpolicy="no-referrer" />
                       <div id="thenights" className="movement-item-text-left">
                           <p>MATT HANSEN “THE NIGHTS”</p>
                       </div>
                    </div>
                    <div onClick={() => window.location.href = "/movement/matt-hansen-break-my-heart"} onMouseEnter={() => setHovering("breakmyheart")} onMouseLeave={() => removeHovering("breakmyheart")} className="movement-item-wrapper">
                       <img className="movement-iframe movement-iframe-right" src="https://lh3.googleusercontent.com/imRS6kQ0CYUtzHx6ZE5nYh4h7tjeQF3TKQPMInq8XG71W9qoZ4qPm6-A6TNtUw2ry0B4p4lQSuEJ-jUYPBhfyJmXBxTz9g3Q-546XnC00WD7O488CsChwVz2msRHZK01I9ElPE6dRX0" referrerpolicy="no-referrer" />
                       <div id="breakmyheart" className="movement-item-text-right">
                           <p>MATT HANSEN "BREAK MY HEART"</p>
                       </div>
                   </div>
                </div>
                <div className="movement-body-row">
                    <div onClick={() => window.location.href = "/movement/matt-hansen-somewhere-only-we-know"} onMouseEnter={() => setHovering("onlyweknow")} onMouseLeave={() => removeHovering("onlyweknow")} className="movement-item-wrapper">
                       <img className="movement-iframe movement-iframe-left" src="https://lh3.googleusercontent.com/-bfSYjiHpiJcxbEKEPnL2l5cdaMC6ybmmyhmWUXepU4KOueHYly1719Iy7MR2KLCH5tMlswG4FgTIeFzYAFJte0ip8JYsesInp6fzuyMhLk2VotK5ueW0KQHNZJABMwByIr9zA-fDw" referrerpolicy="no-referrer" />
                       <div id="onlyweknow" className="movement-item-text-left">
                           <p>MATT HANSEN "SOMEWHERE ONLY WE KNOW"</p>
                       </div>
                   </div>
                    <div onClick={() => window.location.href = "/movement/matt-hansen-joji-cover"} onMouseEnter={() => setHovering("joji")} onMouseLeave={() => removeHovering("joji")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-right" src="https://lh3.googleusercontent.com/XM2RcZqPQsoqYcsbhfB5GrKpTUkktcWs95elhabM5gxJibHyitCxrnNURBefpvlsfU4uUxycdz-nhbp_jW7Q-YV61_bmuoaXVn8IxNFD2wiOAXWm7lvACb4tz4V016L1RPlQo4YXuX8" referrerpolicy="no-referrer"/>
                        <div id="joji" className="movement-item-text-right">
                            <p>MATT HANSEN - JOJI COVER</p>
                        </div>
                    </div>
                </div>
                <div className="movement-body-row">
                    <div onClick={() => window.location.href = "/movement/quinxcii-stacy"} onMouseEnter={() => setHovering("stacy")} onMouseLeave={() => removeHovering("stacy")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-left" src="https://lh3.googleusercontent.com/DDZ3nbvn_ncZ2HY2xsD6sLzzmh0Us5kzpiyebTe1ot9Ndmx1efTBfm_L4WCZClHP0bnL2jjmJKGUBhoN50qTq-ODW068fSAyghcPCAvgy6k6aL9QCedGghkWsLGWCipGHCzd4zroKpM" referrerpolicy="no-referrer" />
                        <div id="stacy" className="movement-item-text-left">
                            <p>QUINN XCII "STACY" (LYRIC VIDEO)</p>
                        </div>
                    </div>
                    <div onClick={() => window.location.href = "/movement/matt-hansen-better-off-without-me-mv"} onMouseEnter={() => setHovering("bowuv")} onMouseLeave={() => removeHovering("bowuv")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-right" src="https://lh3.googleusercontent.com/aKV448jiELxgE2dbRldO0N_AKyzxVuof4v12_WQElqTypfRYb2RIpsA3WuhcOHRkCffBk4P1MqvFuqyCynIBPTo_nZVtYHLm6WIuYstLOXCHSu4aybnGu8yFeD-Ui8dFGYKU8BCu02o" referrerpolicy="no-referrer"/>
                        <div id="bowuv" className="movement-item-text-right">
                            <p>MATT HANSEN "BETTER OFF WITHOUT ME" I (MUSIC VIDEO)</p>
                        </div>
                    </div>
                </div>
                <div className="movement-body-row">
                    <div onClick={() => window.location.href = "/movement/matt-hansen-better-off-without-me-lv"} onMouseEnter={() => setHovering("bowul")} onMouseLeave={() => removeHovering("bowul")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-left" src="https://lh3.googleusercontent.com/1mOk27-oxvoulH66kQUfHZPKjU3bFhYCcVTopn141Uea2wlp0HzbObDv25jPF--9tW1bRz_1uZCVTPdlU8bPMIhFtqxyW3rgZE0wxZvMDssOys-ID-zFhWWtTxBrzMIvsEmm1L6DlpY" referrerpolicy="no-referrer" />
                        <div id="bowul" className="movement-item-text-left">
                            <p>MATT HANSEN "BETTER OFF WITHOUT ME" (LYRIC VIDEO)</p>
                        </div>
                    </div>
                    <div onClick={() => window.location.href = "/movement/cal-roll-the-credits"} onMouseEnter={() => setHovering("roll")} onMouseLeave={() => removeHovering("roll")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-right" src="https://lh3.googleusercontent.com/piWVrpWGIDz72zNYlkJ_quuH5PXs14subpqMoZX-Ktc4CaMf4CUYgwN-dcyPnFR43ce_BE7aFPj4pxZwU_hqVQrv7u6WkRx-AwTO4NYQNexaevd8rJy5ZL0RiodxwKG3Nt1mISijUX0" referrerpolicy="no-referrer"/>
                        <div id="roll" className="movement-item-text-right">
                            <p>CAL "ROLL THE CREDITS"</p>
                        </div>
                    </div>
                </div>
                <div className="movement-body-row">
                    <div onClick={() => window.location.href = "/movement/cal-goodbye-me"} onMouseEnter={() => setHovering("thenitsgoodbye")} onMouseLeave={() => removeHovering("thenitsgoodbye")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-left" src="https://lh3.googleusercontent.com/tS041nHTD9OI2PRcOw_VK_kxZhFMPdJhOHxy9R-rBKqNXNW41yucm_9JRn7pS9hhlQ9Bj6cxheBg_wU8LTxmLTGSP4Qu_ezYKXuETpANP09OnKiMBAq9AzEcCPG7v_65LXhVTz_XKMo" referrerpolicy="no-referrer" />
                        <div id="thenitsgoodbye" className="movement-item-text-left">
                            <p>CAL "GOODBYE, ME"</p>
                        </div>
                    </div>
                    <div onClick={() => window.location.href = "/movement/maude-latour-strangers-forever"} onMouseEnter={() => setHovering("strangersforever")} onMouseLeave={() => removeHovering("strangersforever")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-right" src="https://lh3.googleusercontent.com/643t0UW5tXybP7uEBVAon-YF3On66hkpopJIkjGAU5tUsuPM4ed83W5bhFtYDoi_CYsYy6otSbddZhnPcJvWX-T4srJvgKJigFmphpDB49pnpn3muyOutUKgTX3nHPLlRxQw5dfmB0s" referrerpolicy="no-referrer"/>
                        <div id="strangersforever" className="movement-item-text-right">
                            <p>MAUDE LATOUR "STRANGERS FOREVER" (LYRIC VIDEO)</p>
                        </div>
                    </div>
                </div>
                <div className="movement-body-row">
                    <div onClick={() => window.location.href = "/movement/jessie-murph-look-whose-crying-now"} onMouseEnter={() => setHovering("jessiemurph-1")} onMouseLeave={() => removeHovering("jessiemurph-1")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-left" src="https://lh3.googleusercontent.com/2DCotmCSnyl_GVakgz0_M-KPR5xlicpOiOANbNGZV_sMGAyMnkF2fkIuw_iXDPIvqXgvYSRg7_JPmabm-z1r2x0hqNzO60yLDH1dZxrC_jdogbXWsBgJlKTn6BR_nxeUTJas5wS9uW4" referrerpolicy="no-referrer" />
                        <div id="jessiemurph-1" className="movement-item-text-left">
                            <p>JESSIE MURPH "LOOK WHOSE CRYING NOW"</p>
                        </div>
                    </div>
                    <div onClick={() => window.location.href = "/movement/jessie-murph-always-been-you"} onMouseEnter={() => setHovering("jessiemurph-3")} onMouseLeave={() => removeHovering("jessiemurph-3")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-right" src="https://lh3.googleusercontent.com/7EL9dbL5L96m334f7vTv6wKaprDGBEUzCsZvcc_AgJhzIXwHO1JLbglfkiMW7voYetcRkoA6FCcngsC6uHXXBo-OJSQ3f0lMW5DKwtWYy2TQCOSfiK8me7N9cX3UNH2QPhS70EOeqbs" referrerpolicy="no-referrer" />
                        <div id="jessiemurph-3" className="movement-item-text-right">
                            <p>JESSIE MURPH "ALWAYS BEEN YOU"</p>
                        </div>
                    </div>
                </div>
                <div className="movement-body-row">
                    <div onClick={() => window.location.href = "/movement/slush-puppy-eat-spit"} onMouseEnter={() => setHovering("slushpuppy-1")} onMouseLeave={() => removeHovering("slushpuppy-1")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-left" src="https://lh3.googleusercontent.com/5A5t-u2AeKM1lLjAdQj0IpYmyNtFQJ09y546TpQix1BaZrBUyMhU5SPaER7OhAxx38HhqfQ1IZYmSEcVBAwCA60hOyOAzfbcvtMfMjEWZMQ1qrbc8ePrhkBXoehAiK7czaBrCi1iWb0" referrerpolicy="no-referrer"/>
                        <div id="slushpuppy-1" className="movement-item-text-left">
                            <p>SLUSH PUPPY "EAT SPIT"</p>
                        </div>
                    </div>
                    <div onClick={() => window.location.href = "/movement/slush-puppy-nightmare"} onMouseEnter={() => setHovering("slushpuppy-2")} onMouseLeave={() => removeHovering("slushpuppy-2")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-right" src="https://lh3.googleusercontent.com/VsYe05GE_nQoAPmdx288GXHeF4g5hXByds6gNoXG0ytHu1qw_bcG1T9ntJNMWQZOOnk8f0a371UFWu8fDPGHYHDTbbnhdW38crfj2jPP2N3bnyY7LGMuHA0SvmXHP7zvfcCGemDgTbg" referrerpolicy="no-referrer" />
                        <div id="slushpuppy-2" className="movement-item-text-right">
                            <p>SLUSH PUPPY "NIGHTMARE"</p>
                        </div>
                    </div>
                </div>
                <div className="movement-body-row">
                <   div onClick={() => window.location.href = "/movement/carly-rose-change-my-mind"} onMouseEnter={() => setHovering("changemymind")} onMouseLeave={() => removeHovering("changemymind")} className="movement-item-wrapper">
                        <img style={{objectFit: "cover", objectPosition: "center"}}className="movement-iframe movement-iframe-left" src="https://lh3.googleusercontent.com/sTqe8RMhaZBOwapauqAuXdNPh0xayy98fJaEHw6ixwMNqMad8Pj-fX7z3UG8rWhsP2r_09WNeQxvo-GMy-_lswzqb3HvaZy6_7FabxhbLRvYZaFSdOrWaH56ai8e8LAnRxiYTcTh000" referrerpolicy="no-referrer"/>
                        <div id="changemymind" className="movement-item-text-left">
                            <p>CARLY ROSE "CHANGE MY MIND"</p>
                        </div>
                    </div>
                    <div onClick={() => window.location.href = "/movement/carly-rose-first"} onMouseEnter={() => setHovering("first")} onMouseLeave={() => removeHovering("first")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-right" src="https://lh3.googleusercontent.com/VZCGGRTwS8ukC8vdSN4V-ZzI87S2VIfejqP5ObipI4D6y9QInRtSBIdr1ftFlzZg5EiId2QX7uT-fSGT67Cjo0MNBq3UfrFsXHnH56uSVq_bNbTfMEyVX-0k802FeunnTW_gBbI8kxs" referrerpolicy="no-referrer" />
                        <div id="first" className="movement-item-text-right">
                            <p>CARLY ROSE "FIRST"</p>
                        </div>
                    </div>
                </div>   
                <div className="movement-body-row">
                    <div onClick={() => window.location.href = "/movement/valerie-burrssard-wildflowers"} onMouseEnter={() => setHovering("wildflowers")} onMouseLeave={() => removeHovering("wildflowers")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-left" src="https://lh3.googleusercontent.com/CrEXXUu58lrPzCmQq8uq_Vbfk9Kv2L6FUG4ZX9qvV28NbpsAI4hHd3-_NH6MzJvL4Kf7jmyFKkXcJ3Z6eky5f7pHvBEWf29wbjey3pRspADgxXNo3nnFXeqv5vbQ1cE2SJrndBm_Mp0" referrerpolicy="no-referrer"/>
                        <div id="wildflowers" className="movement-item-text-left">
                            <p>VALERIE BURRSSARD "WILDFLOWERS"</p>
                        </div>
                    </div>
                    <div onClick={() => window.location.href = "/movement/anson-seabra-can-you-hear-me"} onMouseEnter={() => setHovering("canuhearme")} onMouseLeave={() => removeHovering("canuhearme")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-right" src="https://lh3.googleusercontent.com/vU54my5Edp5TMekJVQT6K5lS5PUVyk4or97It_LCK9SMpmVXKZeD_s1xrC1zAZJOFy5hkOD_tKyDv0vkQOzvxv2QLXa3YcFvKU5EdvoD7yKxSsmJhiRnb8h9Sm8C0z8HtqfmBqvhM7w" referrerpolicy="no-referrer" />
                        <div id="canuhearme" className="movement-item-text-right">
                            <p>ANSON SEABRA "CAN YOU HEAR ME"</p>
                        </div>
                    </div>
               </div>
               <div className="movement-body-row">
                    <div onClick={() => window.location.href = "/movement/anson-seabra-dont-forget-to-breathe"} onMouseEnter={() => setHovering("dftb")} onMouseLeave={() => removeHovering("dftb")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-left" src="https://lh3.googleusercontent.com/EF6OlJ5yGiPUB3llqBtD7p0I65lwwAbwSVFsiYcqq14yRd2fePbXBKGeIcQqp3knhbnNlLhIxrPib9cUUd1IUiUWbFmZSUCdrvn1gocYQwYFk46OhMDjx1PpMCEdsCaLAwHlDY-2w0M" referrerpolicy="no-referrer"/>
                        <div id="dftb" className="movement-item-text-left">
                            <p>ANSON SEABRA "DON'T FORGET TO BREATHE"</p>
                        </div>
                    </div>
                    <div onClick={() => window.location.href = "/movement/rosie-darling-coping"} onMouseEnter={() => setHovering("coping")} onMouseLeave={() => removeHovering("coping")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-right" src="https://lh3.googleusercontent.com/obq_1iP-jQ5Nf2NcDbz-TVIgqHT6iVTPygT5Np4hsqWnlShLW0Pg4bpf5s1nKJZNV0tftDybNrlB-mjDrecyNBu3GXKzrEu2NsXLYyNCX5YJV5PMx925RYt2XPd3y4cVqlVLLv-GEks" referrerpolicy="no-referrer" />
                        <div id="coping" className="movement-item-text-right">
                            <p>ROSIE DARLING "COPING"</p>
                        </div>
                    </div>
                </div>
                <div className="movement-body-row">
                    <div onClick={() => window.location.href = "/movement/jillian-rossi-so-what"} onMouseEnter={() => setHovering("sowhat")} onMouseLeave={() => removeHovering("sowhat")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-left" src="https://lh3.googleusercontent.com/aURSBqMEkUSrfRDmCw97fgk6HpvbxBSXRXG43WvVA-P5tndVosdZiQBZueJYFAYda-4b_8exArnuDCs2iZWYXQsrxWX00LpAcNIp9NnA81MLuOzeLOqBp0V3J5QproAqz2nSdPv_jIQ" referrerpolicy="no-referrer" />
                        <div id="sowhat" className="movement-item-text-left">
                            <p>JILLIAN ROSSI "SO WHAT"</p>
                        </div>
                    </div>
                    <div onClick={() => window.location.href = "/movement/juke-ross-atlanta"} onMouseEnter={() => setHovering("atlanta")} onMouseLeave={() => removeHovering("atlanta")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-right" src="https://lh3.googleusercontent.com/jySBk97Gu_tjfd67ODUcJvxkgdqvDpGTFbj_eEH1RY2Mf647J8wDM9_rxObzFAzkj4ICF-XBppowYRsybw6eE-tlzjrCcdH-3F28z24X2Zv89EmetOFj1c8nJ2_aV15MMjKLqt44xZM" referrerpolicy="no-referrer"/>
                        <div id="atlanta" className="movement-item-text-right">
                            <p>JUKE ROSS "ATLANTA"</p>
                        </div>
                    </div> 
                </div> 
                <div className="movement-body-row">
                    <div onClick={() => window.location.href = "/movement/skating-animation"} onMouseEnter={() => setHovering("skating")} onMouseLeave={() => removeHovering("skating")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-left" src="https://lh3.googleusercontent.com/LWuuEnffYfwA67Phe8ryIhmFOuCPgJDKdZqyKPvfVlNR_6IaxoJZc4p4j0N2u2X_bI7ZOCZWQ_zdLeu3xK8Evi9J-0BjWzYZ4vODrv_3gC3V9KdBJXoC_S6AtrA1lFZtu400_ZFPEz4" referrerpolicy="no-referrer" />
                        <div id="skating" className="movement-item-text-left">
                            <p>SKATING ANIMATION</p>
                        </div>
                    </div>
                    <div onClick={() => window.location.href = "/movement/neffex"} onMouseEnter={() => setHovering("neffex")} onMouseLeave={() => removeHovering("neffex")} className="movement-item-wrapper">
                        <img className="movement-iframe movement-iframe-right" src="https://lh3.googleusercontent.com/UXkJWMsScqTeSenY6m9vmo4FwKaJxo0YmFcU1mk8TDjSjcVfYqjQObZgRSMJD9y56xtHEdcRBcgK1Mo5OkyKukmfZlwMuorK_SfxI3FJD__W5k8ps2lrm7ijNTuitz3E7DX-dZG1Yn0" referrerpolicy="no-referrer"/>
                        <div id="neffex" className="movement-item-text-right">
                            <p>NEFFEX</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Movement; 