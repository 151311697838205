import logo from './logo.svg';
import React, {useState} from 'react';
import './App.css';
import WordWheel from './assets/homewheel.svg';

function App() {
  const [amHovering, setAmHovering] = useState('none');

  const setHovering = (menuItem) => {
    //Find 4 p tags from id
    let movement_item = document.getElementById('movement');
    let movement_img = document.getElementById('movement-bkg-img');
    let shape_item = document.getElementById('shape');
    let shape_img = document.getElementById('shape-bkg-img');
    let still_item = document.getElementById('still');
    let still_img = document.getElementById('still-bkg-img');
    let playhouse_item = document.getElementById('playhouse');
    let playhouse_img = document.getElementById('playhouse-bkg-img');
    let background_item = document.getElementById('landing-wrapper');

    switch (menuItem) {
      case "Movement":
        shape_item.style.display = "none";
        still_item.style.display = "none";
        playhouse_item.style.display = "none";
        //background_item.style.backgroundColor = "#98b5c5";
        movement_img.style.display = "block";
        movement_item.style.fontFamily = "NewSpirit";
        break;
      case "Shape":
        movement_item.style.display = "none";
        still_item.style.display = "none";
        playhouse_item.style.display = "none";
        //background_item.style.backgroundColor = "#f5d78a";
        shape_img.style.display = "block";
        shape_item.style.fontFamily = "NewSpirit";
        break;
      case "Still":
        movement_item.style.display = "none";
        shape_item.style.display = "none";
        playhouse_item.style.display = "none";
        still_img.style.display = "block";
        //background_item.style.backgroundColor = "#b9d79d";
        still_item.style.fontFamily = "NewSpirit";
        break;
      case "Playhouse":
        movement_item.style.display = "none";
        shape_item.style.display = "none";
        still_item.style.display = "none";
        playhouse_img.style.display = "block";
        //background_item.style.backgroundColor = "#dc9e93";
        playhouse_item.style.fontFamily = "NewSpirit";
        break;
      default:
        //do nothing
    }
      

  }

  const removeHovering = (menuItem) => {
    let movement_item = document.getElementById('movement');
    let movement_img = document.getElementById('movement-bkg-img');
    let shape_item = document.getElementById('shape');
    let shape_img = document.getElementById('shape-bkg-img');
    let still_item = document.getElementById('still');
    let still_img = document.getElementById('still-bkg-img');
    let playhouse_item = document.getElementById('playhouse');
    let playhouse_img = document.getElementById('playhouse-bkg-img');
    let background_item = document.getElementById('landing-wrapper');

    console.log("In removeHovering: ", menuItem);

    switch (menuItem) {
      case "Movement":
        shape_item.style.display = "block";
        still_item.style.display = "block";
        playhouse_item.style.display = "block";
        movement_img.style.display = "none";
        background_item.style.backgroundColor = "#fff9f1";
        background_item.style.backgroundImage = "none";
        movement_item.style.fontFamily = "BoldNewSpirit";
        break;
      case "Shape":
        movement_item.style.display = "block";
        still_item.style.display = "block";
        playhouse_item.style.display = "block";
        shape_img.style.display = "none";
        background_item.style.backgroundColor = "#fff9f1";
        shape_item.style.fontFamily = "BoldNewSpirit";
        break;
      case "Still":
        movement_item.style.display = "block";
        shape_item.style.display = "block";
        playhouse_item.style.display = "block";
        still_img.style.display = "none";
        background_item.style.backgroundColor = "#fff9f1";
        still_item.style.fontFamily = "BoldNewSpirit";
        break;
      case "Playhouse":
        movement_item.style.display = "block";
        shape_item.style.display = "block";
        still_item.style.display = "block";
        playhouse_img.style.display = "none";
        background_item.style.backgroundColor = "#fff9f1";
        playhouse_item.style.fontFamily = "BoldNewSpirit";
        break;
      default:
        //do nothing
    }
  }

  return (
    <div className="App">
      <div id="landing-wrapper">
        {/*<div>
          <div id="movement-bkg-img">
            <img style={{height: "100vh", width: "100vw", objectFit: "cover", objectPosition: "center"}} src="https://lh3.googleusercontent.com/aV6C8espS0Q-Y9fEzuQP-orL-CQiY5XEGR3DfTXfWxbIKt0DrXZQ3uaLw-fVFBoFmHBeO65fgUO45mGE_nDVOqdlk3iRIB119jVlGOl4d9dqhMB86S8zjezgcJZKFD6acXKA3KTqXyA=w2400" referrerPolicy="no-referrer"/>
          </div>
          <div id="still-bkg-img">
            <img style={{height: "100vh", width: "100vw", objectFit: "cover", objectPosition: "center -33vh"}} src="https://lh3.googleusercontent.com/a9zfCLCov1WQtog_kU2h6WTCtbLC5ZYghf59RxDMiIZnIX67hW7iEhaROHzg5rhHeWsA5I7A0la29FbcAvzb2HZgIIj38UJyCEfLNHcvbwkmnKTZhEHseDKRct5smT7jLZ0dYwmIw5k=w2400" referrerPolicy="no-referrer"/>
          </div>
          <div id="shape-bkg-img">
            <img style={{height: "100vh", width: "100vw", objectFit: "cover", objectPosition: "center"}} src="https://lh3.googleusercontent.com/dJgViTQJDlM7sQegaFh8vvgc36jaYAy7RP_JXB4kH0POyAJR0ITwXS8aBC7DM3mOnL9iv__IldxAMPUEgRJEmxGeAxtXwPJUzfyzKAv6RmQzyK5OdZrjGrizrc3yJV1rE4YBW_mFjkQ=w2400" referrerPolicy="no-referrer"/>
          </div>
          <div id="playhouse-bkg-img">
            <img style={{height: "100vh", width: "100vw", objectFit: "cover", objectPosition: "center"}} src="https://lh3.googleusercontent.com/IWgg5TPfozsPGREFcAmV_tmvwfs-a0CnPXjwq4ZiqDFUiysvT6puQRERZMznjAiz6G_XusadzNDjgLGpdC7Zw5Tyw6H1fKNWL3L-LcWn1gANz3ONiUzZH7uFmCTA-ZQxd7nHhLttg6s=w2400" referrerPolicy="no-referrer"/>
          </div>
        </div>*/}
        <div className="landing-top-wrapper">
          {/*<div className="logo-wrapper">*/}
          <div className="page-logo-wrapper">
            <img style={{width: "100%", margin: "auto"}} src="https://lh3.googleusercontent.com/sOGO8IJR5ZC9Zs3GNYcFu5KjglIaFMOyvWMGFNNYRgs51YR2LnY_67A7raRFMbPZrQQwVRrgYmX-VG0R8F0MF5aDAH17Yu3Ij-JFdiZveZmdw0p3_5JoP8H1VeCx_3ziprad9uiq7kg" referrerPolicy="no-referrer"/>
          </div>
          {/*<div style={{marginRight: '0', marginLeft: 'auto'}} className="word-wheel-wrapper">
            <img className="landing-word-wheel" src={WordWheel} alt="Word Wheel" />
          </div>*/}
        </div>
        <div id="under-construction-about-link-wrapper">
          <p onClick={() => window.location.href = "/about"} id="under-construction-about-link">ABOUT</p>
        </div>
        <div id="under-construction-wrapper">
          <p id="under-construction-text">Currently under construction...</p>
        </div>
        {/*<div className="landing-menu-wrapper">
          <div style={{marginBottom: "2vh"}} className="landing-menu-item">
            <p id="movement"
            onMouseEnter={() => setHovering("Movement")}
            onMouseLeave={() => removeHovering("Movement")}
            onClick={() => window.location.href = "/movement"}
            >Movement</p>
          </div>
          <div className="landing-menu-double-item">
            <div id="still-wrapper">
              <p id="still"
              onMouseEnter={() => setHovering("Still")}
              onMouseLeave={() => removeHovering("Still")}
              onClick={() => window.location.href = "/still"}
              >Still</p>
            </div>
            <div id="shape-wrapper">
              <p id="shape"
              onMouseEnter={() => setHovering("Shape")}
              onMouseLeave={() => removeHovering("Shape")}
              onClick={() => window.location.href = "/shape"}
              >Shape</p>
            </div>
          </div>
          <div style={{marginTop: "2vh"}} className="landing-menu-item">
            <p id="playhouse"
            onMouseEnter={() => setHovering("Playhouse")}
            onMouseLeave={() => removeHovering("Playhouse")}
            onClick={() => window.location.href = "/playhouse"}
            >Playhouse</p>
          </div>
        </div>*/}
        
        {/*<div className="landing-mobile-wrapper">
          <div className="landing-menu-mobile-wrapper">
            <div onClick={() => window.location.href = "/movement"} id="mobile-movement" className="landing-mobile-item">
              <img className="mobile-menu-img" src="https://lh3.googleusercontent.com/aV6C8espS0Q-Y9fEzuQP-orL-CQiY5XEGR3DfTXfWxbIKt0DrXZQ3uaLw-fVFBoFmHBeO65fgUO45mGE_nDVOqdlk3iRIB119jVlGOl4d9dqhMB86S8zjezgcJZKFD6acXKA3KTqXyA=w2400" referrerPolicy="no-referrer"/>
              <p className="landing-mobile-title">Movement</p>
            </div>
            <div onClick={() => window.location.href = "/still"} id="mobile-still" className="landing-mobile-item">
              <img className="mobile-menu-img" src="https://lh3.googleusercontent.com/a9zfCLCov1WQtog_kU2h6WTCtbLC5ZYghf59RxDMiIZnIX67hW7iEhaROHzg5rhHeWsA5I7A0la29FbcAvzb2HZgIIj38UJyCEfLNHcvbwkmnKTZhEHseDKRct5smT7jLZ0dYwmIw5k=w2400" referrerPolicy="no-referrer"/>
              <p className="landing-mobile-title">Still</p>
            </div>
            <div onClick={() => window.location.href = "/shape"} id="mobile-shape" className="landing-mobile-item">
            <img className="mobile-menu-img" src="https://lh3.googleusercontent.com/dJgViTQJDlM7sQegaFh8vvgc36jaYAy7RP_JXB4kH0POyAJR0ITwXS8aBC7DM3mOnL9iv__IldxAMPUEgRJEmxGeAxtXwPJUzfyzKAv6RmQzyK5OdZrjGrizrc3yJV1rE4YBW_mFjkQ=w2400" referrerPolicy="no-referrer"/>
              <p className="landing-mobile-title">Shape</p>
            </div>
            <div onClick={() => window.location.href = "/playhouse"} id="mobile-playhouse" className="landing-mobile-item">
            <img className="mobile-menu-img" src="https://lh3.googleusercontent.com/IWgg5TPfozsPGREFcAmV_tmvwfs-a0CnPXjwq4ZiqDFUiysvT6puQRERZMznjAiz6G_XusadzNDjgLGpdC7Zw5Tyw6H1fKNWL3L-LcWn1gANz3ONiUzZH7uFmCTA-ZQxd7nHhLttg6s=w2400" referrerPolicy="no-referrer"/> 
              <p className="landing-mobile-title">Playhouse</p>
            </div>
          </div>
          <div className="landing-menu-mobile-top-wrapper">
            <div className="logo-wrapper">
              <img style={{width: '25vw'}} src="https://lh3.googleusercontent.com/sOGO8IJR5ZC9Zs3GNYcFu5KjglIaFMOyvWMGFNNYRgs51YR2LnY_67A7raRFMbPZrQQwVRrgYmX-VG0R8F0MF5aDAH17Yu3Ij-JFdiZveZmdw0p3_5JoP8H1VeCx_3ziprad9uiq7kg" referrerPolicy="no-referrer"/>
            </div>
          </div>
        </div>*/}
      </div>
    </div>
  );
}

export default App;
