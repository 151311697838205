import React, { Component } from 'react'
import Client from 'shopify-buy'

const ShopContext = React.createContext()

const client = Client.buildClient({
    //storefrontAccessToken: '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',
    storefrontAccessToken: '94e90b6049adbb8ce81a96ba78834a86',
    domain: 'schmitt-studios.myshopify.com'
})

class ShopProvider extends Component {

    state = {
        products: [],
        product: {},
        checkout: {},
        isCartOpen: false,
        test: "test"                                   
    }

    componentDidMount() {
        this.createCheckout( )
    }

    createCheckout = async () => {
        const checkout = await client.checkout.create().catch(
            e => console.log(e)
        )
        let localCart = JSON.parse(localStorage.getItem('myCheckout'))
        console.log("localCart", localCart)
        if (localCart) {
            this.setState({checkout: localCart})
        }
        else {
            this.setState({checkout: checkout})
        }
    }

    addItemToCheckout = async (variantId, quantity) => {
        const lineItemsToAdd = [{
            variantId,
            quantity: parseInt(quantity, 10)
        }]

        const checkout = await client.checkout.addLineItems(this.state.checkout.id, lineItemsToAdd)
        this.setState({checkout: checkout})
        localStorage.setItem('myCheckout', JSON.stringify(this.state.checkout))
    }

    removeItemFromCheckout = async (variantId, quantity) => {
        let convertedId = btoa(variantId)
        const lineItemIdsToRemove = [
            convertedId
        ];

        const checkout = await client.checkout.removeLineItems(this.state.checkout.id, lineItemIdsToRemove).catch(
            e => console.log(e)
        )
        this.setState({checkout: checkout})
        localStorage.setItem('myCheckout', JSON.stringify(this.state.checkout))
    }

    fetchAllProducts = async () => {
        const products = await client.product.fetchAll().catch(
            e => console.log(e)
        );
        this.setState({products: products})
    }

    fetchProductWithId = async (id) => {
        const product = await client.product.fetch(id).catch(
            e => console.log(e)
        );
        this.setState({product: product})
    }

    fetchProductByHandle = async (handle) => {
        const product = await client.product.fetchByHandle(handle).catch(
            e => console.log(e)
        ) ;
        this.setState({product: product})
    }

    closeCart = () => { this.setState({isCartOpen: false}) }

    openCart = () => { this.setState({isCartOpen: true}) }

    render() {
        return (
            <ShopContext.Provider value={{
                ...this.state,
                fetchAllProducts: this.fetchAllProducts,
                fetchProductWithId: this.fetchProductWithId,
                fetchProductByHandle: this.fetchProductByHandle,
                closeCart: this.closeCart,
                openCart: this.openCart,
                addItemToCheckout: this.addItemToCheckout,
                removeItemFromCheckout: this.removeItemFromCheckout
            }}>
                {this.props.children}
            </ShopContext.Provider>
        )
    }
}

const ShopConsumer = ShopContext.Consumer

export {ShopConsumer, ShopContext}

export default ShopProvider