import React, { useEffect, useContext, useState } from 'react';
import PlayhouseWordWheel from '../assets/playhouse_wheel.svg';
import PlayhouseIcon from '../assets/playhouse_icon.svg';
import { useParams } from 'react-router-dom';
import { ShopContext } from './shopContext'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBagShopping } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const CartItem = (props) => {
    return (
        <React.Fragment>
        <div className="cart-item-wrapper">
            <div className="cart-item-left">
                <img className="cart-item-img" src={props.item.variant.image.src}/>
            </div>
            {console.log(props.item)}
            <div className="cart-item-center">
                <p className="cart-item-title">{props.item.title}</p>
                <p className="cart-item-quantity">Quantity: {props.item.quantity}</p>
                <p onClick={() => props.removeItemFromCart(props.item.id)}className="cart-item-remove">Remove</p>
            </div>
            <div className="cart-item-right">
                <p className="cart-item-price">${props.item.variant.price}</p>
            </div>            
        </div>  
        <hr/>
        </React.Fragment>
    )
}

const Cart = () => {
    const { fetchProductByHandle, addItemToCheckout , removeItemFromCheckout, product, checkout } = useContext(ShopContext)
    const [productName, setProductName] = useState("");
    const [productArtist, setProductArtist] = useState("");
    const [selectedSize, setSelectedSize] = useState(0);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        
    }, [])

    const removeItemFromCart = (productId) => {
        removeItemFromCheckout(productId, 1).then(() => {
            if (checkout.lineItems.length == 1 || checkout.lineItems.length == 0) {
                window.location.href="/playhouse"
            }
        })        
    }

    const handleAddToCart = (productId) => {
        //Call addItemToCheckout
        addItemToCheckout(productId, 1)
        //Display pop up to go to cart or continue shopping
        setShow(true)

    }

    return (
        <React.Fragment>
        <div id="page-wrapper">
            <div className="playhouse-top-wrapper">
                <div onClick={() => window.location.href = "/"} className="page-logo-wrapper">
                    <img style={{width: "100%", margin: "auto"}} src="https://lh3.googleusercontent.com/sOGO8IJR5ZC9Zs3GNYcFu5KjglIaFMOyvWMGFNNYRgs51YR2LnY_67A7raRFMbPZrQQwVRrgYmX-VG0R8F0MF5aDAH17Yu3Ij-JFdiZveZmdw0p3_5JoP8H1VeCx_3ziprad9uiq7kg" referrerpolicy="no-referrer"/>
                </div>
                <div className="top-wrapper-middle">
                        <div className="in-page-nav-wrapper">
                                <p onClick={() => window.location.href = "/movement"} style={{marginRight: '4vw'}} className="in-page-nav-item">MOVEMENT</p>
                                <p onClick={() => window.location.href = "/still"} style={{marginRight: '4vw'}} className="in-page-nav-item">STILL</p>
                                <p onClick={() => window.location.href = "/shape"} style={{marginRight: '4vw'}} className="in-page-nav-item">SHAPE</p>
                                <p onClick={() => window.location.href = "/playhouse"} style={{marginRight: '4vw'}} className="in-page-nav-item">PLAYHOUSE</p>
                                <p onClick={() => window.location.href = "/about"} className="in-page-nav-item">ABOUT</p>
                                {checkout.lineItems != undefined && checkout.lineItems.length > 0 ?
                                    <div className="in-page-nav-cart-link">
                                        <FontAwesomeIcon onClick={() => window.location.href = "/cart"} icon={faBagShopping} />
                                    </div> : <React.Fragment />}
                        </div>
                </div>
                <div className="top-wrapper-right">
                    <div className="page-word-wheel-wrapper">
                        <img className="page-word-wheel" src={PlayhouseWordWheel} alt="Word Wheel" />
                        <img className="page-word-wheel-icon" src={PlayhouseIcon} alt="Movement" />
                    </div>
                </div>
            </div>
            <div style={{background: "var(--playhouse-color)"}} className="top-mobile-nav">
                <div className="mobile-nav-wrapper">
                    <p onClick={() => window.location.href = "/movement"} style={{marginRight: '7vw'}} className="in-page-nav-item">MOVEMENT</p>
                    <p onClick={() => window.location.href = "/still"} style={{marginRight: '7vw'}} className="in-page-nav-item">STILL</p>
                    <p onClick={() => window.location.href = "/shape"} style={{marginRight: '7vw'}} className="in-page-nav-item">SHAPE</p>
                    <p onClick={() => window.location.href = "/playhouse"} style={{marginRight: '7vw'}} className="in-page-nav-item">PLAYHOUSE</p>
                    <p onClick={() => window.location.href = "/about"} className="in-page-nav-item">ABOUT</p>
                    {checkout.lineItems != undefined && checkout.lineItems.length > 0 ?
                        <div className="in-page-nav-cart-link">
                            <FontAwesomeIcon onClick={() => window.location.href = "/cart"} icon={faBagShopping} />
                        </div> : <React.Fragment />}
                </div>
            </div>
            <div className="cart-body-wrapper">
                <p className="cart-title">MY CART</p>
                <div className="cart-wrapper">
                    <div className="cart-left-wrapper">
                        {checkout.lineItems && checkout.lineItems.map(item => {
                            return (
                                <CartItem removeItemFromCart={removeItemFromCart} item={item} />
                            )
                        })}
                    </div>
                    <div className="cart-right-wrapper">
                        <p className="cart-right-title">Order Summary</p>
                        <div className="cart-right-shipping-wrapper">
                            <p className="cart-right-shipping">SHIPPING</p>
                            <p className="cart-right-shipping-price">$6</p>
                        </div>
                        <div style={{height: "2px", width: "100%", backgroundColor: "black"}}/>
                        <div className="cart-right-subtotal-wrapper">
                            <p className="cart-right-subtotal">TOTAL</p>
                            <p className="cart-right-subtotal-price">${parseInt(checkout.subtotalPrice) + 6}</p>
                        </div>
                        <div className="cart-right-checkout-btn">
                            <p onClick={() => window.location.href = checkout.webUrl} className="cart-right-checkout-txt">CHECKOUT</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}

export default Cart