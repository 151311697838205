import React, {useState, useEffect} from 'react';
import ShapeWordWheel from '../assets/shape_wheel.svg';
import ShapeIcon from '../assets/shape_icon.svg';
import ShapeData from '../data/shape_projects_data.json';
import { useParams } from "react-router-dom";
import { Parallax, Background } from "react-parallax";
import { TikTokEmbed } from 'react-social-media-embed';
import { AiOutlineClose } from "react-icons/ai";

//const unscrollable_projects = ["dove-cameron-tour", "cal-roll-the-credits", 
//"gashi-dont-pass-on-love", "gashi-sleeping-on-my-left", "matt-hansen-bowm", "pp-cocaine-level-up",
//"pp-cocaine-krispy-kreme", "rosie-darling-wmd", "anthony-dlt-anyone-else", "anthony-dlt-trust-fall"]

function DiploLayout() {
    const { name } = useParams();

    return (
        <React.Fragment>
            <div className="shape-photos-row">
                <p className="shape-photos-title">{ShapeData[name].title}</p>
                <img className="shape-photo" src={ShapeData[name].images[0]} referrerpolicy="no-referrer"/>
            </div>
            <div className="shape-photos-row">
                <img className="shape-photo-project-big" src={ShapeData[name].images[1]} referrerpolicy="no-referrer" />
            </div>
            <div className="shape-photos-row">
                <img className="shape-photo-project-big" src={ShapeData[name].main_image} referrerpolicy="no-referrer" />
            </div>
        </React.Fragment>
    )
}

function LouisVuitonLayout() {
    const { name } = useParams();

    return (
        <React.Fragment>
            <div className="shape-photos-row">
                <p className="shape-photos-title">{ShapeData[name].title}</p>
                <img className="shape-photo-lv" style={{marginRight: "0"}} src={ShapeData[name].images[0]} referrerpolicy="no-referrer"/>
            </div>
            <div className="shape-photos-row">
                <img className="shape-photo-lv" style={{marginLeft: "0", marginBottom: "5vw"}} src={ShapeData[name].images[1]} referrerpolicy="no-referrer" />
                <img className="shape-photo-lv" style={{marginRight: "0", marginBottom: "5vw"}} src={ShapeData[name].main_image} referrerpolicy="no-referrer"/>
            </div>
        </React.Fragment>
    )
}

function MutualFriedsRRLayout() {
    const { name } = useParams();

    return (
        <React.Fragment>
            <div className="shape-photos-row">
                <p className="shape-photos-title">{ShapeData[name].title}</p>
                <img className="shape-photo-lv" style={{marginRight: "0"}} src={ShapeData[name].images[0]} referrerpolicy="no-referrer"/>
            </div>
            <div className="shape-photos-row">
                <img className="shape-photo-lv" style={{marginLeft: "auto", marginBottom: "5vw", width: "80vw", height: "fit-content"}} src={ShapeData[name].images[1]} referrerpolicy="no-referrer" />
            </div>
            <div className="shape-photos-row">
                <img className="shape-photo-lv" style={{marginLeft: "auto", marginBottom: "5vw", width: "80vw", height: "fit-content"}} src={ShapeData[name].main_image} referrerpolicy="no-referrer"/>
            </div>
        </React.Fragment>
    )
}

function FMWLLayout() {
    const { name } = useParams();

    return (
        <React.Fragment>
            <div className="shape-photos-row">
                <p className="shape-photos-title">{ShapeData[name].title}</p>
                <img className="shape-photo-lv" style={{marginRight: "0"}} src={ShapeData[name].images[0]} referrerpolicy="no-referrer"/>
            </div>
            <div className="shape-photos-row">
                <img className="shape-photo-lv" style={{marginLeft: "auto", marginBottom: "5vw"}} src={ShapeData[name].images[1]} referrerpolicy="no-referrer" />
                <img className="shape-photo-lv" style={{marginLeft: "auto", marginBottom: "5vw"}} src={ShapeData[name].images[2]} referrerpolicy="no-referrer" />
            </div>
            <div className="shape-photos-row">
                <img className="shape-photo-lv" style={{marginLeft: "auto", marginBottom: "5vw"}} src={ShapeData[name].images[3]} referrerpolicy="no-referrer" />
                <img className="shape-photo-lv" style={{marginLeft: "auto", marginBottom: "5vw"}} src={ShapeData[name].images[4]} referrerpolicy="no-referrer" />
            </div>
            <div className="shape-photos-row">
                <img className="shape-photo-lv" style={{marginLeft: "auto", marginBottom: "5vw"}} src={ShapeData[name].images[5]} referrerpolicy="no-referrer" />
                <img className="shape-photo-lv" style={{marginLeft: "auto", marginBottom: "5vw"}} src={ShapeData[name].images[6]} referrerpolicy="no-referrer" />
            </div>
            <div className="shape-photos-row">
                <img className="shape-photo-lv" style={{marginLeft: "auto", marginBottom: "5vw"}} src={ShapeData[name].images[7]} referrerpolicy="no-referrer" />
            </div>
        </React.Fragment>
    )
}

function JessieMurphLayout() {
    const { name } = useParams();

    return (
        <React.Fragment>
            <div className="shape-photos-row">
                <p className="shape-photos-title">{ShapeData[name].title}</p>
                <img className="shape-photo-lv" style={{marginRight: "0"}} src={ShapeData[name].images[0]} referrerpolicy="no-referrer"/>
            </div>
            <div className="shape-photos-row">
                <img className="shape-photo-lv" style={{margin: "auto"}} src={ShapeData[name].images[1]} referrerpolicy="no-referrer" />
            </div>
            <div className="shape-photos-row">
                <img className="shape-photo-lv" style={{margin: "auto", marginBottom: "5vw"}} src={ShapeData[name].main_image} referrerpolicy="no-referrer" />
            </div>
        </React.Fragment>
    )
}

function TroopLayout() {
    const { name } = useParams();

    return (
        <React.Fragment>
            <div className="shape-photos-row">
                <p className="shape-photos-title">{ShapeData[name].title}</p>
                <img className="shape-photo" src={ShapeData[name].images[0]} referrerpolicy="no-referrer"/>
            </div>
            <div className="shape-photos-row">
                <img className="shape-photo-project-big" style={{height: "60vw", marginBottom: '0', width: "fit-content"}} src={ShapeData[name].images[1]} referrerpolicy="no-referrer" />
            </div>
            <div className="shape-photos-row">
                <img className="shape-photo-project-big" style={{height: "60vw", width: "fit-content"}} src={ShapeData[name].main_image} referrerpolicy="no-referrer" />
            </div>
        </React.Fragment>
    )
}

function RDCopingLayout() {
    const { name } = useParams();

    return (
        <React.Fragment>
            <div style={{paddingTop: "0"}} className="shape-photos-row-double">
                <p className="shape-photos-title">{ShapeData[name].title}</p>
                <img className="shape-photo-remi" style={{marginRight: "0"}} src={ShapeData[name].images[0]} referrerpolicy="no-referrer"/>
            </div>
            <div style={{paddingTop: "0"}} className="shape-photos-row-double">
                <img className="shape-photo-remi" style={{marginLeft: "0"}} src={ShapeData[name].images[1]} referrerpolicy="no-referrer" />
                <img className="shape-photo-remi" style={{marginLeft: "auto"}} src={ShapeData[name].images[2]} referrerpolicy="no-referrer" />
            </div>
            <div style={{paddingTop: "0"}} className="shape-photos-row-double">
                <img className="shape-photo-remi" style={{marginLeft: "0"}} src={ShapeData[name].images[3]} referrerpolicy="no-referrer" />
                <img className="shape-photo-remi" style={{marginLeft: "auto"}} src={ShapeData[name].images[4]} referrerpolicy="no-referrer" />
            </div>
            <div style={{paddingTop: "0"}} className="shape-photos-row-double">
                <img className="shape-photo-remi" style={{marginLeft: "0"}} src={ShapeData[name].images[5]} referrerpolicy="no-referrer" />
                <img className="shape-photo-remi" style={{marginLeft: "auto"}} src={ShapeData[name].images[6]} referrerpolicy="no-referrer" />
            </div>
            <div style={{paddingTop: "0"}} className="shape-photos-row-double">
                <img className="shape-photo-remi" style={{marginLeft: "auto", marginRight: "auto", paddingBottom: "5vw"}} src={ShapeData[name].main_image} referrerpolicy="no-referrer" />
            </div>
        </React.Fragment>
    )
}

function RemiWolfLayout() {
    const { name } = useParams();

    return (
        <React.Fragment>
            <div className="shape-photos-row-double">
                <p className="shape-photos-title">{ShapeData[name].title}</p>
                <img className="shape-photo-remi" style={{marginRight: "0"}} src={ShapeData[name].images[0]} referrerpolicy="no-referrer"/>
            </div>
            <div className="shape-photos-row-double">
                <img className="shape-photo-remi" style={{marginLeft: "0"}} src={ShapeData[name].images[1]} referrerpolicy="no-referrer" />
                <img className="shape-photo-remi" style={{marginLeft: "auto"}} src={ShapeData[name].images[2]} referrerpolicy="no-referrer" />
            </div>
            <div className="shape-photos-row-double">
                <img className="shape-photo-remi" style={{marginLeft: "0"}} src={ShapeData[name].images[3]} referrerpolicy="no-referrer" />
                <img className="shape-photo-remi" style={{marginLeft: "auto"}} src={ShapeData[name].images[4]} referrerpolicy="no-referrer" />
            </div>
            <div className="shape-photos-row-double">
                <img className="shape-photo-remi" style={{marginLeft: "0"}} src={ShapeData[name].images[5]} referrerpolicy="no-referrer" />
                <img className="shape-photo-remi" style={{marginLeft: "auto"}} src={ShapeData[name].images[6]} referrerpolicy="no-referrer" />
            </div>
            <div className="shape-photos-row-double">
                <img className="shape-photo-remi" style={{marginLeft: "0", marginBottom: "5vw"}} src={ShapeData[name].images[7]} referrerpolicy="no-referrer" />
                <img className="shape-photo-remi" style={{marginLeft: "auto", marginBottom: "5vw"}} src={ShapeData[name].main_image} referrerpolicy="no-referrer" />
            </div>
        </React.Fragment>
    )
}

function BillNyeLayout() {
    const { name } = useParams();

    return (
        <React.Fragment>
            <div className="shape-photos-row">
                <p className="shape-photos-title">{ShapeData[name].title}</p>
                <TikTokEmbed className="bill-nye-top-tt" url="https://www.tiktok.com/@billnye/video/7089523503141063979?is_from_webapp=1&sender_device=pc&web_id=7065815502668727814" />
            </div>
            <div className="shape-photos-row">
                <TikTokEmbed className="bill-nye-mid-tt" style={{marginRight: "2.5vw"}} url="https://www.tiktok.com/@billnye/video/7094387490475347246?is_from_webapp=1&sender_device=pc&web_id=7065815502668727814" />
                <TikTokEmbed className="bill-nye-mid-tt" style={{marginLeft: "2.5vw"}} url="https://www.tiktok.com/@billnye/video/7099521188371631402?is_from_webapp=1&sender_device=pc&web_id=7065815502668727814" />
            </div>
            <div className="shape-photos-row">
                <TikTokEmbed className="bill-nye-mid-tt" style={{marginRight: "2.5vw"}} url="https://www.tiktok.com/@billnye/video/7106633730655128874?is_from_webapp=1&sender_device=pc&web_id=7065815502668727814" />
                <TikTokEmbed className="bill-nye-mid-tt" style={{marginLeft: "2.5vw", paddingBottom: "5vw"}} url="https://www.tiktok.com/@billnye/video/7112543918775864622?is_from_webapp=1&sender_device=pc&web_id=7065815502668727814" />
            </div>
        </React.Fragment>
    )
}

function PPCocaineLayout() {
    const { name } = useParams();

    return (
        <React.Fragment>
            <div className="shape-photos-row">
                <p className="shape-photos-title">{ShapeData[name].title}</p>
                <div className="pp-cocaine-video">
                    <iframe className="pp-cocaine-iframe" src={ShapeData[name].vid} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </React.Fragment>
    )
}

function SinglePhotoLayout() {
    const { name } = useParams();

    return (
        <React.Fragment>
            <div className="shape-photos-row">
                <p className="shape-photos-title">{ShapeData[name].title}</p>
                <img className="shape-photo-half" style={{marginLeft: "auto", height: "fit-content", paddingBottom: "5vw"}} src={ShapeData[name].main_image} referrerpolicy="no-referrer"/>
            </div>
        </React.Fragment>
    )
}
 
function ShapeProject() {
    const { name } = useParams();

    const renderSwitch = (project_name) => {
        console.log(project_name)
        switch(project_name) {
            case "diplo-x-crocs": 
                return <DiploLayout />
            case "louis-vuiton-city-of-stars":
                return <LouisVuitonLayout />
            case "mutual-friends-rr":
                return <MutualFriedsRRLayout />
            case "fmwl": 
                return <FMWLLayout />
            case "jessie-murph-sobriety-merch":
                return <JessieMurphLayout />
            case "troop": 
                return <TroopLayout />
            case "rosie-darling-coping":
                return <RDCopingLayout />
            case "remi-wolf-tour-visuals": 
                return <RemiWolfLayout />
            case "bill-nye":
                return <BillNyeLayout />
            case "pp-cocaine-level-up":
                return <PPCocaineLayout />
            case "pp-cocaine-krispy-kreme":
                return <PPCocaineLayout />
            case "dove-cameron-tour":
                return <SinglePhotoLayout />
            case "cal-roll-the-credits":
                return <SinglePhotoLayout />
            case "gashi-dont-pass-on-love":
                return <SinglePhotoLayout />
            case "gashi-sleeping-on-my-left":
                return <SinglePhotoLayout />
            case "matt-hansen-bowm":
                return <SinglePhotoLayout />
            case "rosie-darling-wmd":
                return <SinglePhotoLayout />    
            default:
                return <React.Fragment />
        }
    }

    /*const unscrollableProject = (name) => {
        if (unscrollable_projects.includes(name)) {
            return true
        } else {
            return false
        }
    }*/

    return (
        <div id="page-wrapper">
            <div style={{position: 'absolute', backgroundColor: "rgba(0, 0, 0, 0)", zIndex: "3", color: "white"}} className="shape-top-wrapper">
                <div onClick={() => window.location.href = "/"} className="page-logo-wrapper">
                        <img style={{width: "100%", margin: "auto"}} src="https://lh3.googleusercontent.com/sOGO8IJR5ZC9Zs3GNYcFu5KjglIaFMOyvWMGFNNYRgs51YR2LnY_67A7raRFMbPZrQQwVRrgYmX-VG0R8F0MF5aDAH17Yu3Ij-JFdiZveZmdw0p3_5JoP8H1VeCx_3ziprad9uiq7kg" referrerpolicy="no-referrer"/>
                </div>
                <div className="top-wrapper-middle">
                        <div className="in-page-nav-wrapper">
                                <p onClick={() => window.location.href = "/movement"} style={{marginRight: '4vw'}} className="in-page-nav-item">MOVEMENT</p>
                                <p onClick={() => window.location.href = "/still"} style={{marginRight: '4vw'}} className="in-page-nav-item">STILL</p>
                                <p onClick={() => window.location.href = "/shape"} style={{marginRight: '4vw', fontFamily: "BoldNewSpirit"}} className="in-page-nav-item">SHAPE</p>
                                <p onClick={() => window.location.href = "/playhouse"} style={{marginRight: '4vw'}} className="in-page-nav-item">PLAYHOUSE</p>
                                <p onClick={() => window.location.href = "/about"} className="in-page-nav-item">ABOUT</p>
                        </div>
                    </div>
                <div style={{display: "flex"}} className="top-wrapper-right">
                    <p onClick={() => window.location.href = "/shape"} style={{margin: "auto", marginRight: "5vw", color: "white", fontFamily: "NewSpirit", fontSize: "36px"}} className="shape-project-x"><AiOutlineClose className="close-icon" /></p>
                </div>
            </div>
            <div style={{position: "absolute", zIndex: "3", color: "white", marginTop: "7vh"}} className="top-mobile-nav">
                <div className="mobile-nav-wrapper">
                    <p onClick={() => window.location.href = "/movement"} style={{marginRight: '7vw'}} className="in-page-nav-item">MOVEMENT</p>
                    <p onClick={() => window.location.href = "/still"} style={{marginRight: '7vw'}} className="in-page-nav-item">STILL</p>
                    <p onClick={() => window.location.href = "/shape"} style={{marginRight: '7vw', fontFamily: "BoldNewSpirit"}} className="in-page-nav-item">SHAPE</p>
                    <p onClick={() => window.location.href = "/playhouse"} style={{marginRight: '7vw'}} className="in-page-nav-item">PLAYHOUSE</p>
                    <p onClick={() => window.location.href = "/about"} className="in-page-nav-item">ABOUT</p>
                </div>
            </div>
            <div className="shape-body-wrapper">
                <Parallax
                    style={{height: '100vh', display: "flex"}}
                    strength={700}
                >
                    <Background>
                        <img style={{objectPosition: '0 -35vh !important', objectFit: "cover", height: "100vh", width: "100vw"}} src={ShapeData[name].main_image}/>
                    </Background>
                    <div style={{margin: "auto"}}>
                        <p className="parallax-text">{ShapeData[name].title}</p>
                    </div>
                    
                </Parallax>
                <div className="shape-photos-wrapper">
                    {renderSwitch(name)}
                </div>
            </div>
        </div>
    )
}

export default ShapeProject;