import React, { useEffect, useContext, useState } from 'react';
import PlayhouseWordWheel from '../assets/playhouse_wheel.svg';
import PlayhouseIcon from '../assets/playhouse_icon.svg';
import { useParams } from 'react-router-dom';
import { ShopContext } from './shopContext'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBagShopping } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Added to cart
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="product-popup-body">
            <img className="product-popup-img" src={props.productImg} />
            <div className="product-popup-info">
                <p className="product-popup-title" >{props.productTitle}</p>
                <p className="product-popup-size" >Size: {props.productSize}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
            <Button className="product-popup-shopping-btn" onClick={() => window.location.href = "/playhouse"}>Continue Shopping</Button>
            <Button className="product-popup-cart-btn" onClick={() => window.location.href = "/cart"}>View Cart</Button>
        </Modal.Footer>
      </Modal>
    );
  }

const ProductPage = () => {
    let { handle } = useParams();
    const { fetchProductByHandle, addItemToCheckout , product, checkout } = useContext(ShopContext)
    const [productName, setProductName] = useState("");
    const [productArtist, setProductArtist] = useState("");
    const [selectedSize, setSelectedSize] = useState(0);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        fetchProductByHandle(handle)
    }, [fetchProductByHandle, handle])

    const handleAddToCart = (productId) => {
        //Call addItemToCheckout
        addItemToCheckout(productId, 1)
        //Display pop up to go to cart or continue shopping
        setShow(true)

    }

    if (product.handle) {
        return (
            <React.Fragment>
            <div id="page-wrapper">
                <div className="playhouse-top-wrapper">
                    <div onClick={() => window.location.href = "/"} className="page-logo-wrapper">
                        <img style={{width: "100%", margin: "auto"}} src="https://lh3.googleusercontent.com/sOGO8IJR5ZC9Zs3GNYcFu5KjglIaFMOyvWMGFNNYRgs51YR2LnY_67A7raRFMbPZrQQwVRrgYmX-VG0R8F0MF5aDAH17Yu3Ij-JFdiZveZmdw0p3_5JoP8H1VeCx_3ziprad9uiq7kg" referrerpolicy="no-referrer"/>
                    </div>
                    <div className="top-wrapper-middle">
                            <div className="in-page-nav-wrapper">
                                    <p onClick={() => window.location.href = "/movement"} style={{marginRight: '4vw'}} className="in-page-nav-item">MOVEMENT</p>
                                    <p onClick={() => window.location.href = "/still"} style={{marginRight: '4vw'}} className="in-page-nav-item">STILL</p>
                                    <p onClick={() => window.location.href = "/shape"} style={{marginRight: '4vw'}} className="in-page-nav-item">SHAPE</p>
                                    <p onClick={() => window.location.href = "/playhouse"} style={{marginRight: '4vw', fontFamily: "BoldNewSpirit"}} className="in-page-nav-item">PLAYHOUSE</p>
                                    <p onClick={() => window.location.href = "/about"} className="in-page-nav-item">ABOUT</p>
                                    {checkout.lineItems != undefined && checkout.lineItems.length > 0 ?
                                        <div className="in-page-nav-cart-link">
                                            <FontAwesomeIcon onClick={() => window.location.href = "/cart"} icon={faBagShopping} />
                                        </div> : <React.Fragment />}
                            </div>
                    </div>
                    <div className="top-wrapper-right">
                        <div className="page-word-wheel-wrapper">
                            <img className="page-word-wheel" src={PlayhouseWordWheel} alt="Word Wheel" />
                            <img className="page-word-wheel-icon" src={PlayhouseIcon} alt="Movement" />
                        </div>
                    </div>
                </div>
                <div style={{background: "var(--playhouse-color)"}} className="top-mobile-nav">
                    <div className="mobile-nav-wrapper">
                        <p onClick={() => window.location.href = "/movement"} style={{marginRight: '7vw'}} className="in-page-nav-item">MOVEMENT</p>
                        <p onClick={() => window.location.href = "/still"} style={{marginRight: '7vw'}} className="in-page-nav-item">STILL</p>
                        <p onClick={() => window.location.href = "/shape"} style={{marginRight: '7vw'}} className="in-page-nav-item">SHAPE</p>
                        <p onClick={() => window.location.href = "/playhouse"} style={{marginRight: '7vw', fontFamily: "BoldNewSpirit"}} className="in-page-nav-item">PLAYHOUSE</p>
                        <p onClick={() => window.location.href = "/about"} className="in-page-nav-item">ABOUT</p>
                        {checkout.lineItems != undefined && checkout.lineItems.length > 0 ?
                            <div className="in-page-nav-cart-link">
                                <FontAwesomeIcon onClick={() => window.location.href = "/cart"} icon={faBagShopping} />
                            </div> : <React.Fragment />}
                    </div>
                </div>
                <div className="playhouse-body-wrapper">
                    {/*<div className="playhouse-comingsoon-wrapper">
                        <p className="playhouse-comingsoon">Our world of chaos coming soon...</p>
                    </div>*/}
                    <div className="playhouse-product-page-wrapper">
                        <div className="product-page-left-wrapper">
                            <div className="product-page-inner-wrapper">
                                <div className="product-page-image-frame">
                                    <img alt="Product Image" className="product-page-image" src={product.images[0].src} />
                                </div>
                                {/*<p className="product-price">{product.variants[0].price}</p>*/}
                            </div>
                        </div>
                        <div className="product-page-right-wrapper">
                            <div className="product-information-wrapper">                                
                                <p className="product-page-title">{product.title.split("|")[0]}</p>
                                <p className="product-page-artist">{product.title.split("|")[1]}</p>
                                <p className="product-page-description">{product.description}</p>
                                {product.variants.length > 1 ? 
                                <React.Fragment>
                                    <p className="product-page-artist">CHOOSE YOUR SIZE</p>
                                    {product.variants.map((variant, index) => {
                                        return (
                                            <div onClick={() => setSelectedSize(index)} style={selectedSize == index ? {border: "1px black solid"} : {}} className="product-variant-selection">
                                                <p className="product-variant-size">{variant.selectedOptions[0].value}</p>
                                                <p className="product-variant-price">${variant.price}</p>
                                            </div>
                                        )
                                    })
                                    }
                                    <div style={{marginTop: '1vw'}} onClick={() => handleAddToCart(product.variants[selectedSize].id)} className="product-page-addtocart-btn">
                                        <p className="product-page-addtocart-title">Add to Cart</p>
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <p className="product-page-price">${product.variants[0].price}</p>
                                    <div onClick={() => handleAddToCart(product.variants[0].id)} className="product-page-addtocart-btn">
                                        <p className="product-page-addtocart-title">Add to Cart</p>
                                    </div>
                                </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MyVerticallyCenteredModal
            show={show}
            onHide={() => setShow(false)}
            productImg={product.images[0].src}
            productTitle={product.title.split("|")[0]}
            productSize={product.variants[selectedSize].selectedOptions[0].value}
            />
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
            <div id="page-wrapper">
                <div className="playhouse-top-wrapper">
                    <div onClick={() => window.location.href = "/"} className="page-logo-wrapper">
                        <img style={{width: "100%", margin: "auto"}} src="https://lh3.googleusercontent.com/sOGO8IJR5ZC9Zs3GNYcFu5KjglIaFMOyvWMGFNNYRgs51YR2LnY_67A7raRFMbPZrQQwVRrgYmX-VG0R8F0MF5aDAH17Yu3Ij-JFdiZveZmdw0p3_5JoP8H1VeCx_3ziprad9uiq7kg" referrerpolicy="no-referrer"/>
                    </div>
                    <div className="top-wrapper-middle">
                            <div className="in-page-nav-wrapper">
                                    <p onClick={() => window.location.href = "/movement"} style={{marginRight: '4vw'}} className="in-page-nav-item">MOVEMENT</p>
                                    <p onClick={() => window.location.href = "/still"} style={{marginRight: '4vw'}} className="in-page-nav-item">STILL</p>
                                    <p onClick={() => window.location.href = "/shape"} style={{marginRight: '4vw'}} className="in-page-nav-item">SHAPE</p>
                                    <p onClick={() => window.location.href = "/playhouse"} style={{marginRight: '4vw', fontFamily: "BoldNewSpirit"}} className="in-page-nav-item">PLAYHOUSE</p>
                                    <p onClick={() => window.location.href = "/about"} className="in-page-nav-item">ABOUT</p>
                            </div>
                        </div>
                    <div className="top-wrapper-right">
                        <div className="page-word-wheel-wrapper">
                            <img className="page-word-wheel" src={PlayhouseWordWheel} alt="Word Wheel" />
                            <img className="page-word-wheel-icon" src={PlayhouseIcon} alt="Movement" />
                        </div>
                    </div>
                </div>
                <div style={{background: "var(--playhouse-color)"}} className="top-mobile-nav">
                    <div className="mobile-nav-wrapper">
                        <p onClick={() => window.location.href = "/movement"} style={{marginRight: '7vw'}} className="in-page-nav-item">MOVEMENT</p>
                        <p onClick={() => window.location.href = "/still"} style={{marginRight: '7vw'}} className="in-page-nav-item">STILL</p>
                        <p onClick={() => window.location.href = "/shape"} style={{marginRight: '7vw'}} className="in-page-nav-item">SHAPE</p>
                        <p onClick={() => window.location.href = "/playhouse"} style={{marginRight: '7vw', fontFamily: "BoldNewSpirit"}} className="in-page-nav-item">PLAYHOUSE</p>
                        <p onClick={() => window.location.href = "/about"} className="in-page-nav-item">ABOUT</p>
                    </div>
                </div>
                <div className="playhouse-body-wrapper">
                    {/*<div className="playhouse-comingsoon-wrapper">
                        <p className="playhouse-comingsoon">Our world of chaos coming soon...</p>
                    </div>*/}
                    <div className="playhouse-store-wrapper">
                        <p className="playhouse-store-loading">Loading...</p>
                    </div>
                </div>
            </div>
            <MyVerticallyCenteredModal
            show={show}
            onHide={() => setShow(false)}
          />
          </React.Fragment>
        )
    }
}

export default ProductPage