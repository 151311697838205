import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Movement from './components/Movement.js';
import MovementVid from './components/MovementVid.js';
import Playhouse from './components/Playhouse.js';
import Shape from './components/Shape.js';
import Still from './components/Still.js';
import About from  './components/About.js';
import ShapeProject from './components/ShapeProject.js';
import ShopProvider from './components/shopContext.js';
import ProductPage from './components/ProductPage.js';
import Cart from './components/Cart.js';

ReactDOM.render(
  <React.StrictMode>
    <ShopProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<App />}/>
          <Route exact path="/home" element={<App />}/>
          <Route exact path="movement" element={<Movement />} />
          <Route exact path="/movement/:name" element={<MovementVid />} />
          <Route exact path="playhouse" element={<Playhouse />} />
          <Route exact path="/playhouse/product/:handle" element={<ProductPage />}/>
          <Route exact path="shape" element={<Shape />} />
          <Route exact path="still" element={<Still />} />
          <Route exact path="about" element={<About />} />
          <Route exact path="/shape/:name" element={<ShapeProject />} />
          <Route exact path="/cart" element={<Cart />} />
        </Routes>
      </BrowserRouter>
    </ShopProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
