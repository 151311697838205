import React, {useState, useEffect, useCallback, useContext} from 'react';
import StillWordWheel from '../assets/still_wheel.svg';
import StillIcon from '../assets/still_icon.svg';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "../data/stillphotos";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBagShopping } from '@fortawesome/free-solid-svg-icons'
import { ShopContext } from './shopContext'

function Still() {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const { fetchProductByHandle, addItemToCheckout , product, checkout } = useContext(ShopContext)

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    function columns(containerWidth) {
        let columns = 1;
        if (containerWidth >= 500) columns = 2;
        if (containerWidth >= 900) columns = 4;
        if (containerWidth >= 1500) columns = 5;
        return columns;
      }
    
    return (
        <div id="page-wrapper">
            <div className="still-top-wrapper">
                <div onClick={() => window.location.href = "/"} className="page-logo-wrapper">
                    <img style={{width: "100%", margin: "auto"}} src="https://lh3.googleusercontent.com/sOGO8IJR5ZC9Zs3GNYcFu5KjglIaFMOyvWMGFNNYRgs51YR2LnY_67A7raRFMbPZrQQwVRrgYmX-VG0R8F0MF5aDAH17Yu3Ij-JFdiZveZmdw0p3_5JoP8H1VeCx_3ziprad9uiq7kg" referrerpolicy="no-referrer"/>
                </div>
                <div className="top-wrapper-middle">
                        <div className="in-page-nav-wrapper">
                                <p onClick={() => window.location.href = "/movement"} style={{marginRight: '4vw'}} className="in-page-nav-item">MOVEMENT</p>
                                <p onClick={() => window.location.href = "/still"} style={{marginRight: '4vw', fontFamily: "BoldNewSpirit"}} className="in-page-nav-item">STILL</p>
                                <p onClick={() => window.location.href = "/shape"} style={{marginRight: '4vw'}} className="in-page-nav-item">SHAPE</p>
                                <p onClick={() => window.location.href = "/playhouse"} style={{marginRight: '4vw'}} className="in-page-nav-item">PLAYHOUSE</p>
                                <p onClick={() => window.location.href = "/about"} className="in-page-nav-item">ABOUT</p>
                                {checkout != undefined ? 
                                (checkout.lineItems != undefined && checkout.lineItems.length)> 0 ? 
                                <div className="in-page-nav-cart-link">
                                    <FontAwesomeIcon onClick={() => window.location.href = "/cart"} icon={faBagShopping} />
                                </div> : <React.Fragment /> : <React.Fragment />}
                        </div>
                    </div>
                <div className="top-wrapper-right">
                    <div className="page-word-wheel-wrapper">
                        <img className="page-word-wheel" src={StillWordWheel} alt="Word Wheel" />
                        <img className="page-word-wheel-icon" src={StillIcon} alt="Still" />
                    </div>
                </div>
            </div>
            <div style={{background: "var(--still-color)"}} className="top-mobile-nav">
                <div className="mobile-nav-wrapper">
                    <p onClick={() => window.location.href = "/movement"} style={{marginRight: '7vw'}} className="in-page-nav-item">MOVEMENT</p>
                    <p onClick={() => window.location.href = "/still"} style={{marginRight: '7vw', fontFamily: "BoldNewSpirit"}} className="in-page-nav-item">STILL</p>
                    <p onClick={() => window.location.href = "/shape"} style={{marginRight: '7vw'}} className="in-page-nav-item">SHAPE</p>
                    <p onClick={() => window.location.href = "/playhouse"} style={{marginRight: '7vw'}} className="in-page-nav-item">PLAYHOUSE</p>
                    <p onClick={() => window.location.href = "/about"} className="in-page-nav-item">ABOUT</p>
                    {checkout != undefined ? 
                        (checkout.lineItems != undefined && checkout.lineItems.length)> 0 ?
                        <div className="in-page-nav-cart-link">
                            <FontAwesomeIcon onClick={() => window.location.href = "/cart"} icon={faBagShopping} />
                        </div> : <React.Fragment /> : <React.Fragment />}
                </div>
            </div>
            <div style={{backgroundColor: "var(--still-color)"}} className="page-slogan-wrapper">
                <p className="page-slogan">A Small Moment In Time</p>
            </div>
            <div className="still-body-wrapper">
                <Gallery photos={photos} direction={"column"} columns={columns} onClick={openLightbox} referrerpolicy="no-referrer"/>
                <ModalGateway>
                    {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                        currentIndex={currentImage}
                        views={photos.map(x => ({
                            ...x,
                            srcset: x.srcSet,
                            caption: x.title
                        }))}
                        />
                    </Modal>
                    ) : null}
                </ModalGateway>
            </div>
        </div>
    )
}

export default Still; 