import React, {useState, useEffect, useContext} from 'react';
import PlayhouseWordWheel from '../assets/playhouse_wheel.svg';
import PlayhouseIcon from '../assets/playhouse_icon.svg';
import { ShopContext } from './shopContext'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBagShopping } from '@fortawesome/free-solid-svg-icons'

const debugShop = true

function Playhouse() {
    const { fetchAllProducts, products } = useContext(ShopContext)
    const { fetchProductByHandle, addItemToCheckout , product, checkout } = useContext(ShopContext)

    useEffect(() => {
        fetchAllProducts()
    }, [fetchAllProducts])

    return (
        <div id="page-wrapper">
            <div className="playhouse-top-wrapper">
                <div onClick={() => window.location.href = "/"} className="page-logo-wrapper">
                    <img style={{width: "100%", margin: "auto"}} src="https://lh3.googleusercontent.com/sOGO8IJR5ZC9Zs3GNYcFu5KjglIaFMOyvWMGFNNYRgs51YR2LnY_67A7raRFMbPZrQQwVRrgYmX-VG0R8F0MF5aDAH17Yu3Ij-JFdiZveZmdw0p3_5JoP8H1VeCx_3ziprad9uiq7kg" referrerpolicy="no-referrer"/>
                </div>
                <div className="top-wrapper-middle">
                        <div className="in-page-nav-wrapper">
                                <p onClick={() => window.location.href = "/movement"} style={{marginRight: '4vw'}} className="in-page-nav-item">MOVEMENT</p>
                                <p onClick={() => window.location.href = "/still"} style={{marginRight: '4vw'}} className="in-page-nav-item">STILL</p>
                                <p onClick={() => window.location.href = "/shape"} style={{marginRight: '4vw'}} className="in-page-nav-item">SHAPE</p>
                                <p onClick={() => window.location.href = "/playhouse"} style={{marginRight: '4vw', fontFamily: "BoldNewSpirit"}} className="in-page-nav-item">PLAYHOUSE</p>
                                <p onClick={() => window.location.href = "/about"} className="in-page-nav-item">ABOUT</p>
                                {checkout != undefined ? 
                                (checkout.lineItems != undefined && checkout.lineItems.length)> 0 ? 
                                <div className="in-page-nav-cart-link">
                                    <FontAwesomeIcon onClick={() => window.location.href = "/cart"} icon={faBagShopping} />
                                </div> : <React.Fragment /> : <React.Fragment />}
                        </div>
                    </div>
                <div className="top-wrapper-right">
                    <div className="page-word-wheel-wrapper">
                        <img className="page-word-wheel" src={PlayhouseWordWheel} alt="Word Wheel" />
                        <img className="page-word-wheel-icon" src={PlayhouseIcon} alt="Movement" />
                    </div>
                </div>
            </div>
            <div style={{background: "var(--playhouse-color)"}} className="top-mobile-nav">
                <div className="mobile-nav-wrapper">
                    <p onClick={() => window.location.href = "/movement"} style={{marginRight: '7vw'}} className="in-page-nav-item">MOVEMENT</p>
                    <p onClick={() => window.location.href = "/still"} style={{marginRight: '7vw'}} className="in-page-nav-item">STILL</p>
                    <p onClick={() => window.location.href = "/shape"} style={{marginRight: '7vw'}} className="in-page-nav-item">SHAPE</p>
                    <p onClick={() => window.location.href = "/playhouse"} style={{marginRight: '7vw', fontFamily: "BoldNewSpirit"}} className="in-page-nav-item">PLAYHOUSE</p>
                    <p onClick={() => window.location.href = "/about"} className="in-page-nav-item">ABOUT</p>
                    {checkout != undefined ? 
                        (checkout.lineItems != undefined && checkout.lineItems.length)> 0 ?
                        <div className="in-page-nav-cart-link">
                            <FontAwesomeIcon onClick={() => window.location.href = "/cart"} icon={faBagShopping} />
                        </div> : <React.Fragment /> : <React.Fragment />}
                </div>
            </div>
            <div style={{backgroundColor: "var(--playhouse-color)"}} className="page-slogan-wrapper">
                {debugShop === true ? <p className="page-slogan">Prints will be back soon!</p> : <p className="page-slogan">Limited prints for sale</p>}
            </div>
            <div className="playhouse-body-wrapper">
                {/*<div className="playhouse-comingsoon-wrapper">
                    <p className="playhouse-comingsoon">Our world of chaos coming soon...</p>
                </div>*/}
                <div className="playhouse-store-wrapper">
                    {products && products.map(product => {
                        return (<div className="product-outer-wrapper" key={product.handle}>
                            <Link to={`/playhouse/product/${product.handle}`}>
                                <div className="product-inner-wrapper">
                                    <div className="product-image-frame">
                                        <img alt="Product Image" className="product-image" src={product.images[0].src} />
                                    </div>
                                    <div className="product-info-wrapper">
                                        <p className="product-title">{product.title}</p>
                                    </div>
                                    {/*<p className="product-price">{product.variants[0].price}</p>*/}
                                </div>
                            </Link>
                        </div>)
                    })}
                </div>
            </div>
        </div>
    )
}

export default Playhouse; 